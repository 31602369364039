import {combineReducers} from "redux";
import { routerReducer } from 'react-router-redux'

import giftNowReducer from "GiftNow/reducers/GiftNowReducer.js"
import redeemNowReducer from "RedeemNow/reducers/RedeemNowReducer.js"
import aboutUsReducer from "AboutUs/reducers/AboutUs.js"
import faqReducer from "FAQ/reducers/FAQReducer.js"
import funIdeasReducer from "FunIdeas/reducers/FunIdeasReducer.js"
import donateReducer from "Donate/reducers/DonateReducer.js"


export default combineReducers({
    router: routerReducer,
    giftNow: giftNowReducer,
    redeemNow: redeemNowReducer,
    aboutUs: aboutUsReducer,
    faq: faqReducer,
    funIdeas: funIdeasReducer,
    donate: donateReducer,

})