import React, {Component} from 'react';
import {connect} from "react-redux";

import { CommonServices } from 'site/services';

import { GiftCardImageServices  } from 'GiftNow/components/GiftCardImage';
import { RedeemNowCategorySelection } from 'RedeemNow/components';

class RedemptionPreview extends Component {

    componentDidMount() {
        window.scrollTo(0,0);
    }

    commonServices = new CommonServices();

    render() {

        if ( this.props.giftCardInfo.customGreetingEnabled ){
            return (
                <div>
                    <div className={"row m-0"}>
                        <RedeemNowCategorySelection
                            isPreview={true}
                            startPreview = {this.props.startPreview}
                            giftCardInfo={this.props.giftCardInfo}
                            text={this.props.giftCardInfo.customGreetingText}
                            greetingFontSize = {this.props.giftCardInfo.greetingFontSize}
                            greetingLineHeight= {this.props.giftCardInfo.greetingLineHeight}
                            recipientName={this.props.giftCardInfo.recipientName}
                            purchaserName={this.props.giftCardInfo.purchaserName}
                            amount={this.props.giftCardInfo.amount}

                        />
                    </div>
                </div>
            );


        } else {

            let imageServices = new GiftCardImageServices(this.props);
            let greeting = imageServices.getGreetingById(this.props.giftCardInfo.greetingId);

            return (
                <div>
                    <div className={"row m-0"}>
                        <RedeemNowCategorySelection
                            text={this.commonServices.renderWithLineBreaks(imageServices.replaceGreetingText(greeting.header, this.props.giftCardInfo.recipientName, this.props.giftCardInfo.purchaserName))}
                            isPreview={true}
                            startPreview = {this.props.startPreview}
                            giftCardInfo={this.props.giftCardInfo}
                            greetingFontSize = {this.props.giftCardInfo.greetingFontSize}
                            greetingLineHeight= {this.props.giftCardInfo.greetingLineHeight}
                            recipientName={this.props.giftCardInfo.recipientName}
                            purchaserName={this.props.giftCardInfo.purchaserName}
                            amount={this.props.giftCardInfo.amount}
                        />
                    </div>
                </div>
            );
        }

    }
}


const mapStateToProps = (state) => {
    return {
        greetings: state.giftNow.greetings,
        customGreeting: state.giftNow.customGreeting,
        giftCardInfo : state.giftNow.giftCardInfo


    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(RedemptionPreview );

