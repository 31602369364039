import React, {Component} from 'react';
import {connect} from "react-redux";

import 'RedeemNow/css/RedeemNow.scss'
import {
    RedeemNowCodeEntry,
    RedeemNowCategorySelection,
    RedeemNowCharitySelection,
    RedeemNowThankYou

} from './';


class RedeemNow extends Component {

    componentDidMount() {
        window.scrollTo(0,0);
    }

    render() {

        switch (this.props.currentRedeemNowStep) {
            case 1:
                return <RedeemNowCodeEntry />
            case 2:
                return <RedeemNowCategorySelection />
            case 3:
                return <RedeemNowCharitySelection  />
            case 4:
                return <RedeemNowThankYou />
            default:
                return <RedeemNowCodeEntry />
        }
    }
}


const mapStateToProps = (state) => {
    return {
        currentRedeemNowStep : state.redeemNow.redeemNowStep
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(RedeemNow);

