import * as Actions from "../actions/FunIdeasActions";

const initialState = {
    funIdeas: [

    ]
};

export default function funIdeasReducer(state = initialState, action) {

    switch(action.type) {

        case Actions.FUN_IDEAS_UPDATE:
            console.log("FUN_IDEAS_UPDATE:::");
            console.log(action.funIdeas);
            return Object.assign({}, state, { funIdeas: action.funIdeas });

        default:
            return state;
    }
}
