import React, {Component} from 'react';
import {connect} from "react-redux";
import 'FunIdeas/scss/FunIdeas.scss';

class FunIdeasCard extends Component {

    renderTitle = () => {
        return (
            <div className={"row"}>
                <div className={"col-12"}>
                    {this.props.heading}-{this.props.key}
                </div>
            </div>
        );
    };

    renderBody = () => {
        if (this.props.iconUrl ){
            return (
                <div className={"row fun-ideas-card h-100"}>
                    <div className={"col-md-6 my-auto"}>
                        <img className={"fun-ideas-card-image rounded mx-auto d-block mw-100"} src={this.props.iconUrl} alt={"image for " + this.props.heading }/>
                    </div>
                    <div className={"col-md-6 my-auto text-left"}>
                        {this.props.text}
                    </div>
                </div>
            );
        } else {
            return (
                <div className={"row fun-ideas-card"}>
                    <div className={"col-12 my-auto text-left"}>
                        {this.props.text}
                    </div>
                </div>
            );
        }

    };

    render() {
        return (
            <div className={"card h-100"}>

                <div className={"card-header text-left"}>
                    {this.renderTitle()}
                </div>


                <div className={"card-body text-left"}>
                    {this.renderBody()}
                </div>

            </div>
        )
    }
}



const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(FunIdeasCard);

