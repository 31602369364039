import React, {Component} from 'react';
import {connect} from "react-redux";
import validator from 'validator';
import CurrencyInput from 'react-currency-input-field';

import * as Actions from "../actions/GiftNowActions";

import { InputField, InputRadio, DropDownInputField, LoadingOverlay, Error } from 'site/components';
import { GiftCardImagePreview, GiftCardImageServices  } from 'GiftNow/components/GiftCardImage';

import 'GiftNow/css/GiftNow.scss'

class GiftCardInformationForm extends Component {

    constructor(props){
        super(props);
        this.toggleCustomGreetingEnabled = this.toggleCustomGreetingEnabled.bind(this);
        this.isCustomGreeting = this.isCustomGreeting.bind(this);
        this.state = {
            authorizationCodes : ["test","kidsgiving"]
        }
    }

    componentDidMount() {

        this.props.getCardtypes();

        if( this.props.paymentComplete ){
            console.log("Payment was completed, resetting to initial state.");
            this.props.resetToInitialState();
            this.props.getCardtypes();
        }
        window.scrollTo(0,0);

    }

    validateAndAdvanceStep = () => {
        let errors = {};

        if ( !this.props.giftCardInfo ){
            console.log("something went wrong.");
            window.location.reload();
        }

        if ( !this.isCustomGreeting() ) {
            this.props.giftCartGreetingFontSize(32);
            if (!this.props.giftCardInfo.recipientName || this.props.giftCardInfo.recipientName === "") {
                errors.recipientName = "Please include the recipient's name";
            }
            if (!this.props.giftCardInfo.greetingId || this.props.giftCardInfo.greetingId === "") {
                errors.greetingId = "Please select a greeting";
            }
        } else {
            if (!this.props.giftCardInfo.customGreetingText || this.props.giftCardInfo.customGreetingText === "") {
                errors.customGreetingText = "Please provide a greeting";
            }
        }

        if ( !this.props.giftCardInfo.email || this.props.giftCardInfo.email === "") {
            errors.email = "Please include your email";
        } else if (!validator.isEmail(this.props.giftCardInfo.email)){
            errors.email = "Please include a valid email";
        }

        if ( !this.props.giftCardInfo.amount
            || this.props.giftCardInfo.amount === ""
            || isNaN(this.props.giftCardInfo.amount)
            || this.props.giftCardInfo.amount < 0
            || this.props.giftCardInfo.amount > 100000

        ){
            errors.amount = "Please include a valid gift card amount";
        }

        if ( Object.keys(errors).length === 0 && errors.constructor === Object ){
            if (!this.props.giftCardInfo.purchaserName || this.props.giftCardInfo.purchaserName === "") {
                this.props.giftCardInfoChangePurchaserName("Someone");
            }
            this.props.updateGiftCardErrors(errors);
            this.props.advanceToNextGiftNowStep(this.props.currentGiftNowStep);
        } else{
            console.log("Errors");
            console.log(errors);
            this.props.updateGiftCardErrors(errors);
        }
    };

    displayGiftCard(){
        let purchaserName = "Someone";
        if ( this.props.giftCardInfo.purchaserName ){
            purchaserName = this.props.giftCardInfo.purchaserName;
        }
        if (
            (
                !this.props.giftCardInfo.customGreetingEnabled
                && this.props.giftCardInfo.greetingId && this.props.giftCardInfo.greetingId !== ""
                && this.props.giftCardInfo.recipientName && this.props.giftCardInfo.recipientName !== ""
            ) || (
                this.props.giftCardInfo.customGreetingEnabled
                && this.props.giftCardInfo.customGreetingText && this.props.giftCardInfo.customGreetingText !== ""
            )
        ){
            let imageServices = new GiftCardImageServices(this.props);
            if (!this.isCustomGreeting()){
                let greeting = imageServices.getGreetingById(this.props.giftCardInfo.greetingId);
                if ( greeting ){
                    /*console.log("this.props.greetingFontSize");
                    console.log(this.props.giftCardInfo.greetingFontSize);*/
                    return (
                        <GiftCardImagePreview
                            headerText = {imageServices.replaceGreetingText(greeting.header, this.props.giftCardInfo.recipientName, purchaserName)}
                            line1 = {imageServices.replaceGreetingText(greeting.line1, this.props.giftCardInfo.recipientName, purchaserName)}
                            line2 = {imageServices.replaceGreetingText(greeting.line2, this.props.giftCardInfo.recipientName, purchaserName)}
                            line3 = {imageServices.replaceGreetingText(greeting.line3, this.props.giftCardInfo.recipientName, purchaserName)}
                            small = {false}
                            greetingFontSize = {32}
                            greetingLineHeight= {44}
                            passRefsUp={this.refsFromImagePreview}
                        />

                    );
                }

            } else {
                let greeting = this.props.customGreeting;
                let greetingLine1 = "{purchaser_name} has given you the gift of giving!";
                return (
                    <GiftCardImagePreview
                        headerText = {this.props.giftCardInfo.customGreetingText}
                        greetingFontSize = {this.props.giftCardInfo.greetingFontSize}
                        greetingLineHeight= {this.props.giftCardInfo.greetingLineHeight}
                        line1 = {imageServices.replaceGreetingText(greetingLine1, null, purchaserName)}
                        line2 = {greeting.line2}
                        line3 = {greeting.line3}
                        small = {false}
                        passRefsUp={this.refsFromImagePreview}
                    />
                );
            }

        } else {
            return (
                <div className={"no-card-info-container row align-items-center"}>
                    <div className={"col"}>
                        <h4>let's get a little information first..</h4>
                    </div>
                </div>
            )
        }
    };

    toggleCustomGreetingEnabled = () => {
        this.props.giftCardInfoSetCustomGreetingEnabled(!this.props.giftCardInfo.customGreetingEnabled);
    };

    toggleDonationEnabled = () => {
        this.props.giftCardInfoSetDonationEnabled(!this.props.giftCardInfo.donationEnabled);
    };

    isCustomGreeting = () => {
        return this.props.giftCardInfo.customGreetingEnabled;
    };

    giftCardInfoSetCustomGreetingText = (value) => {
        if(value && value.length <= 36){
            this.props.giftCardInfoSetCustomGreetingText(value);
            this.props.giftCartGreetingFontSize(32);
        } else if(value && value.length <= 42){
            this.props.giftCardInfoSetCustomGreetingText(value);
            this.props.giftCartGreetingFontSize(26);
        } else if(value && value.length <= 76){
            this.props.giftCardInfoSetCustomGreetingText(value);
            this.props.giftCartGreetingFontSize(20);
        } else if(value && value.length <= 135){
            this.props.giftCardInfoSetCustomGreetingText(value);
            this.props.giftCartGreetingFontSize(14);
        } else if ( !value ){
            this.props.giftCardInfoSetCustomGreetingText("");
        }
    };

    displayGreetingInput = () => {
        if ( this.isCustomGreeting() ){
            return (
                <InputField label={"greeting"} value={this.props.giftCardInfo.customGreetingText}
                            onChange={(e) => this.giftCardInfoSetCustomGreetingText(e.target.value) }
                            hideHelperText = {true}
                            error={this.props.errors.purchaserName}/>
            );
        } else {
            let filteredGreetings = [];
            for ( let greetingIndex in this.props.greetings ){
                if ( this.props.greetings[greetingIndex].displayName && this.props.greetings[greetingIndex].displayName.toLowerCase() !== "custom" ){
                    filteredGreetings.push(this.props.greetings[greetingIndex]);
                }
            }

            return (
                <div>
                    <DropDownInputField
                        label={"greeting"}
                        defaultValue={ this.props.giftCardInfo.greetingId }
                        onChange={(e) => this.props.giftCardInfoChangeGreetingId(e.target.value) }
                        options={ filteredGreetings }
                        error ={this.props.errors.greetingId}
                        hideHelperText = {true} />
                </div>
            );
        }


    };

    giftCardInfoChangeRecipientName = (value) =>{
        if(value && value.length < 22){
            this.props.giftCardInfoChangeRecipientName(value);
        } else if ( !value ){
            this.props.giftCardInfoChangeRecipientName(null);
        }

    };
    displayRecipientNameInput = () => {
        if ( !this.isCustomGreeting() ){
            return (
                <div className={"mb-3"}>
                    <InputField label={"to"} value={this.props.giftCardInfo.recipientName}
                                onChange={(e) => this.giftCardInfoChangeRecipientName(e.target.value) }
                                helperText={"how would you like their name to appear on the card?\n(don't worry, we'll get their shipping information later!)"}
                                error={this.props.errors.recipientName}/>
                </div>
            );
        }
    };

    giftCardInfoChangePurchaserName = (value) => {
        if(value && value.length < 25){
            this.props.giftCardInfoChangePurchaserName(value);
        } else if ( !value ){
            this.props.giftCardInfoChangePurchaserName(null);
        }
    };
    displayPurchaserNameInput = () => {
        return (
            <div className={"mb-3"}>
                <InputField label={"from"} value={this.props.giftCardInfo.purchaserName}
                            onChange={(e) => this.giftCardInfoChangePurchaserName(e.target.value) }
                            helperText={"how would you like your name to appear on the card?"}
                            error={this.props.errors.purchaserName}
                            autoComplete={"first-name"}
                />
            </div>
        );
    };

    displayAmountInputs = () => {
        if ( !this.props.giftCardInfo.customAmountEnabled ){
            return (
                <div className={"row justify-content-center"}>

                    <div className={"col text-center"}>
                        <InputRadio
                            label={"$20"}
                            value={ 20.00 }
                            checkedAmount = { this.props.giftCardInfo.amount }
                            onChange={(e) => this.props.giftCardInfoChangeAmount(e.target.value) }
                        />
                    </div>
                    <div className={"col"}>
                        <InputRadio
                            label={"$40"}
                            value={ 40.00 }
                            checkedAmount = { this.props.giftCardInfo.amount }
                            onChange={(e) => this.props.giftCardInfoChangeAmount(e.target.value) }
                        />
                    </div>
                    <div className={"col"}>
                        <InputRadio
                            label={"$50"}
                            value={ 50.00 }
                            checkedAmount = { this.props.giftCardInfo.amount }
                            onChange={(e) => this.props.giftCardInfoChangeAmount(e.target.value) }
                        />
                    </div>
                    {/*toggle other*/}
                    <div className={"col"}>

                        <input type="checkbox" aria-label="custom amount" className={"checkbox align-middle m-0 mt-1 mb-2 clickable"}
                               onChange={ (e) => this.props.giftCardInfoToggleCustomAmount(this.props.giftCardInfo.customAmountEnabled) }
                               value={ this.props.giftCardInfo.customAmountEnabled  ? this.props.giftCardInfo.customAmountEnabled  : false }
                               checked={ this.props.giftCardInfo.customAmountEnabled  ? this.props.giftCardInfo.customAmountEnabled  : false }
                        />
                        <div className={"row clear-both display-block justify-content-center"}>
                            other
                        </div>

                    </div>

                </div>


            );
        } else {
            return (
                <div>
                    <div className="input-group">
                        <div className="input-group-prepend col-5 col-sm-4 pr-0 ">
                            <span className="input-group-text input-label-element justify-content-end input-field-label" >amount</span>
                        </div>
                        {/*Other*/}
                        <CurrencyInput
                            aria-label="custom amount"
                            className="form-control"
                            name="custom-amount"
                            placeholder="Please enter a gift amount"
                            prefix="$"
                            defaultValue={this.props.giftCardInfo.amount}
                            decimalsLimit={2}
                            decimalScale={2}
                            onValueChange={(value) => this.props.giftCardInfoChangeAmount(value)}
                        />
                    </div>
                </div>
            );
        }
    };

    resetCardTemplate = () => {
        console.log("resetting card template");
        this.props.resetToInitialState();
        this.props.getCardtypes();
    };

    updateAuthorizationCode = (code) => {
        this.props.giftCardInfoAuthorizationCode(code);
    };

    isAuthorized = () => {
        return ( this.state.authorizationCodes.indexOf(this.props.authorizationCode) !== -1 );
    };

    render() {

        if ( !this.isAuthorized() && false ) {
            return (
                <div className={"w-100"}>
                    <div className={"row title-row"}>
                        <div className={"col-12"}>
                            <h4>we're still working on the site.  do you have a beta code?</h4>
                        </div>
                    </div>
                    <div className={"row m-0 mt-3 m-md-4 align-items-center justify-content-center"}>
                        <div className={"col-lg-6  "}>
                            <div className={"card card-body yellow-box-shadow redeem-now-card-entry opacity m-3"}>
                                <div className={"row m-2"}>
                                    <div className={"col-12"}>
                                        <span className={"redeem-now-text"}>
                                            enter your code here
                                        </span>
                                    </div>
                                </div>
                                <div className={"row m-2"}>
                                    <div className={"col-12"}>
                                        <input type="text" className="form-control" value={this.props.authorizationCode} aria-label="Default" aria-describedby="inputGroup-sizing-default"

                                               onChange={
                                            (e) => {
                                                this.updateAuthorizationCode(e.target.value);
                                            }

                                        }/>
                                    </div>
                                    <div>{this.state.authorizationCode}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            );

        } else if (this.props.hasError) {
            return <Error/>;

        } else {
            return (
                <div>
                    <LoadingOverlay isLoading={this.props.isLoading}/>

                    <div className={"w-100"}>
                        <div className={"row title-row"}>
                            <div className={"col-12"}>
                                <h4>what would you like your gift to look like?</h4>
                            </div>
                        </div>

                        <div className={"row mt-4 ml-0 mr-0"}>
                            <div className={"col-md-6 offset-xl-2 col-xl-4"}>

                                <div className={"row"}>
                                    <div className={"col-12 col-md-8 offset-md-2 mb-3 mt-3"}>
                                        <h5 className={"justify-content-center "}>build your card</h5>
                                    </div>
                                    <div className={"col-12 col-md-2 text-right mb-2 mt-4"}>
                                        <p className={"reset-card-button"} onClick={this.resetCardTemplate}>reset</p>
                                    </div>
                                </div>

                                <div className={"mb-3"}>

                                    {this.displayGreetingInput()}

                                    <div className={"input-group"}>
                                        <div className={"offset-1 col-1 helper-text text-left mt-2 input "}>
                                            <input type="checkbox" aria-label="checkbox for following text input"
                                                   className={"checkbox align-middle clickable "}
                                                   onChange={this.toggleCustomGreetingEnabled}
                                                   value={this.props.giftCardInfo.customGreetingEnabled ? this.props.giftCardInfo.customGreetingEnabled : false}
                                                   checked={this.props.giftCardInfo.customGreetingEnabled ? this.props.giftCardInfo.customGreetingEnabled : false}
                                            />
                                        </div>
                                        <div className={"col-10 helper-text text-left mt-2"}>
                                            <span className={" align-middle "}> i'd prefer some custom text </span>
                                        </div>
                                    </div>

                                    {this.isCustomGreeting()}

                                </div>

                                {this.displayRecipientNameInput()}
                                {this.displayPurchaserNameInput()}

                                <div className={"mb-3"}>
                                    <InputField label={"your email"}
                                                value={this.props.giftCardInfo.email}
                                                onChange={(e) => this.props.giftCardInfoChangeEmail(e.target.value)}
                                                helperText={"we'll let you know when the card has been sent"}
                                                error={this.props.errors.email}
                                                autoComplete={"email"}
                                    />
                                </div>

                                <div className={"mb-3"}>


                                    <div className={"row"}>
                                        <div className={"col-12 text-center helper-text  mb-3"}>
                                            this amount will go to the selected charity when the token is redeemed
                                        </div>
                                    </div>

                                    {this.displayAmountInputs()}

                                    {this.props.errors.amount ? (
                                        <div className={"row clear-both display-block justify-content-center mt-0"}>
                                            <span
                                                className={"m-1 ml-4 p-0 input-error-message"}>{this.props.errors.amount}</span>
                                        </div>
                                    ) : ""}

                                </div>

                                <div className={"mb-3"}>
                                    <div className={"input-group "}>
                                        <div className={"offset-1 col-1 helper-text text-left mt-2"}>
                                            <input type="checkbox" aria-label="checkbox for following text input"
                                                   className={"checkbox align-middle "}
                                                   onChange={this.toggleDonationEnabled}
                                                   value={this.props.giftCardInfo.donationEnabled ? this.props.giftCardInfo.donationEnabled : false}
                                                   checked={this.props.giftCardInfo.donationEnabled ? this.props.giftCardInfo.donationEnabled : false}
                                            />
                                        </div>
                                        <div className={"col-10 helper-text text-left mt-2"}>
                                            <span className={" "}>i would like to add $1 to my donation to support kidsgiving</span>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className={"col-md-6 col-lg-4 mt-5"}>
                                <div className={"card-preview-container mt-2"}>
                                    {this.displayGiftCard()}
                                </div>
                            </div>
                        </div>

                        <div className={"row m-0"}>
                            <div className={"col-12 justify-content-center"}>
                                <button type="button" className={"btn btn-primary pl-5 pr-5 m-5"}
                                        onClick={this.validateAndAdvanceStep}>continue to shipping options
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            )
        }

    }
}

const mapStateToProps = (state) => {

    return {
        currentGiftNowStep : state.giftNow.giftNowStep,
        giftCardInfo : state.giftNow.giftCardInfo,
        shipToInfo: state.giftNow.shipToInfo,
        greetings: state.giftNow.greetings,
        authorizationCode: state.giftNow.authorizationCode,
        authorizationCodes: state.giftNow.authorizationCodes,
        customGreeting: state.giftNow.customGreeting,
        errors: state.giftNow.errors,
        paymentComplete: state.giftNow.paymentComplete,
        isLoading: !state.giftNow.isCardTypesLoaded,
        hasError: state.giftNow.hasError
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        advanceToNextGiftNowStep : (currentGiftNowStep) => dispatch(Actions.advanceToNextGiftNowStep(currentGiftNowStep)),
        getCardtypes: () => {dispatch (Actions.getCardtypes())},
        resetToInitialState: () => {dispatch({type:Actions.RESET_TO_INITIAL_STATE})},

        giftCardInfoChangePurchaserName : (value) => dispatch ({type: Actions.GIFT_CARD_INFO_CHANGE_PURCHASER_NAME, value : value}),
        giftCardInfoChangeRecipientName : (value) => dispatch ({type: Actions.GIFT_CARD_INFO_CHANGE_RECIPIENT_NAME, value : value}),
        giftCardInfoChangeGreetingId : (value) => dispatch ({type: Actions.GIFT_CARD_INFO_CHANGE_GREETING_ID, value : value}),
        giftCardInfoSetCustomGreetingEnabled: (value) => dispatch ({type: Actions.GIFT_CARD_INFO_SET_CUSTOM_GREETING_ENABLED, value : value}),
        giftCardInfoSetDonationEnabled: (value) => dispatch ({type: Actions.GIFT_CARD_INFO_SET_DONATION_ENABLED, value : value}),
        giftCardInfoSetCustomGreetingText: (value) => dispatch ({type: Actions.GIFT_CARD_INFO_SET_CUSTOM_GREETING_TEXT, value : value}),
        giftCartGreetingFontSize: (value) => dispatch ({type: Actions.GIFT_CARD_INFO_SET_GREETING_FONT_SIZE, value : value}),
        giftCardInfoChangeEmail : (value) => dispatch ({type: Actions.GIFT_CARD_INFO_CHANGE_EMAIL, value : value}),
        giftCardInfoChangeAmount : (value) => dispatch ({type: Actions.GIFT_CARD_INFO_CHANGE_AMOUNT, value : value}),
        giftCardInfoToggleCustomAmount : (value) => dispatch ({type: Actions.GIFT_CARD_INFO_TOGGLE_CUSTOM_AMOUNT, value : value}),
        giftCardInfoAuthorizationCode : (value) => dispatch ({type: Actions.GIFT_CARD_INFO_AUTHORIZATION_CODE, value : value}),

        giftCardShipToChangeFirstName : (value) => dispatch ({type: Actions.SHIP_TO_INFO_CHANGE_FIRST_NAME, value : value}),
        giftCardShipToChangeLastName : (value) => dispatch ({type: Actions.SHIP_TO_INFO_CHANGE_LAST_NAME, value : value}),
        giftCardShipToChangeAddress1 : (value) => dispatch ({type: Actions.SHIP_TO_INFO_CHANGE_ADDRESS1, value : value}),
        giftCardShipToChangeAddress2 : (value) => dispatch ({type: Actions.SHIP_TO_INFO_CHANGE_ADDRESS2, value : value}),
        giftCardShipToChangeCity : (value) => dispatch ({type: Actions.SHIP_TO_INFO_CHANGE_CITY, value : value}),
        giftCardShipToChangeState : (value) => dispatch ({type: Actions.SHIP_TO_INFO_CHANGE_STATE, value : value}),
        giftCardShipToChangeZipcode : (value) => dispatch ({type: Actions.SHIP_TO_INFO_CHANGE_ZIPCODE, value : value}),
        giftCardShipToChangeCountry : (value) => dispatch ({type: Actions.SHIP_TO_INFO_CHANGE_COUNTRY, value : value}),
        updateGiftCardErrors : (errors) => { dispatch({type: Actions.UPDATE_GIFT_CARD_ERRORS, value: errors}) }
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(GiftCardInformationForm);

