import React, { Component } from 'react';
import {connect} from "react-redux";
import 'site/css/PreviewBanner.scss'

class PreviewBanner extends Component {

    render() {

        if ( !this.props.isPreview ){
            console.log("is NOT preview");
            return null;
        } else {
            console.log("is preview");
            return(
                <div className="row w-p100 preview-banner-row m-0">
                    <div className="col-12 text-center">
                        <span className="preview-banner-text align-middle">you are currently in preview mode</span>
                    </div>
                </div>
            )
        }

    }
}


const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};


export default connect(mapStateToProps, mapDispatchToProps)(PreviewBanner);


