import React, {Component} from 'react';
import {connect} from "react-redux";

import { CommonServices } from 'site/services';

import blankCard from 'images/kidsgiving_gift-card_L2b_basic-3.png';
import 'GiftNow/css/GiftNow.scss'

class GiftCardImagePreview extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fade: "card-hide"
        };

    }

    componentDidMount() {

        setTimeout(() => {
            this.setState({
                fade: "card-show"
            });
        }, 1);

    }

    renderWithLineBreaks(text){

        if ( text ){
            return text.replace("\\n","\n");
        }
    }

    dividerLine( isSmall ){
        return (
            <div className={ isSmall ? "divider-dots-small" : "divider-dots" }>....................</div>
        )
    }

    commonServices = new CommonServices();

    render() {


        let headerTextStyle = {
            "fontSize" : this.props.greetingFontSize + "px",
            "lineHeight" : this.props.greetingLineHeight + "px",
        };

        if ( !this.props.small ){

            return (
                <div>
                    <div className={"card-preview-container " + this.state.fade}>
                        <img src={blankCard} className={"card-preview-image"} alt={"gift card preview"}/>
                        <div className={"card-preview-overlay-container"}>
                            <div className={"mt-2"}>{this.dividerLine(false)}</div>
                            <div ref="CardOverlayText" className={"row card-preview-overlay-header-text"} style={headerTextStyle} >
                                <p className={"card-preview-overlay-header-text-inner align-self-center mx-auto"}>
                                    {this.commonServices.renderWithLineBreaks(this.props.headerText)}
                                </p>
                            </div>
                            {this.dividerLine(false)}
                            <div className={"card-preview-overlay-text card-preview-overlay-line1"}>{this.commonServices.renderWithLineBreaks(this.props.line1)}</div>
                            <div className={"card-preview-ring-background-spacer"} />
                            <div className={"card-preview-overlay-text card-preview-overlay-line2"}>
                                <div className={"clear"}>...then go to</div>
                                <div className={"clear bold-link"}>kidsgiving.us/kidspage</div>
                                <div className={"clear"}>and choose how to</div>
                                <div className={"clear"}>make a difference!</div>
                            </div>

                            {/*<div className={"card-preview-overlay-text card-preview-overlay-line2"}>{this.commonServices.renderWithLineBreaks(this.props.line2)}</div>*/}
                            {/*<div className={"card-preview-overlay-text card-preview-overlay-line3"}>{this.commonServices.renderWithLineBreaks(this.props.line3)}</div>*/}


                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div className={"card-preview-container-small " + this.state.fade}>
                        <img src={blankCard} className={"card-preview-image-small"} alt={"small gift card preview"} />
                        <div className={"card-preview-overlay-container-small"}>
                            <div className={"mt-2"}>{this.dividerLine(false)}</div>
                            <div className={"card-preview-overlay-header-text-small"}>{this.commonServices.renderWithLineBreaks(this.props.headerText )}</div>
                            {this.dividerLine(true)}
                            <div className={"card-preview-overlay-text-small card-preview-overlay-line1-small"}>{this.commonServices.renderWithLineBreaks(this.props.line1)}</div>
                            <div className={"card-preview-ring-background-spacer-small"} />
                            <div className={"card-preview-overlay-text card-preview-overlay-line2-small"}>
                                <div className={"clear"}>...then go to</div>
                                <div className={"clear bold-link"}>kidsgiving.us/kidspage</div>
                                <div className={"clear"}>and choose how to</div>
                                <div className={"clear"}>make a difference!</div>
                            </div>

                        </div>
                    </div>
                </div>
            );
        }

    }
}




const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(GiftCardImagePreview);

