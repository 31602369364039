import React, { Component } from 'react';
import {connect} from "react-redux";
import 'site/css/TitleBar.css'

class TitleBar extends Component {

    /*
        original title called for geotica-w01-four-open,fantasy
        which is not a free font.  using:
        https://fonts.googleapis.com/css?family=Coming+Soon
        instead.  fonts available at:
        https://fonts.google.com/
    */


    render() {
        return(
            <div className="row w-p100 m-0">
                <div className="col-12 text-center">
                    <span className="title-bar-text">kidsgiving</span>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};


export default connect(mapStateToProps, mapDispatchToProps)(TitleBar);


