import React, {Component} from 'react';
import {connect} from "react-redux";

import * as Actions from "../actions/FaqActions";
import { FaqCard } from './';

import 'FAQ/css/FAQ.css';

class FAQ extends Component {

    componentDidMount() {
        window.scrollTo(0,0);

        try{
            this.props.getFaqs();
        } catch(e) {
            console.log("Error loading faqs:");
            console.log(e);
        }
    }

    render() {
        return (
            <div className={"faq-background-container"}>
                <div className={"row m-0"}>
                    <div className={"col-12 offset-lg-2 col-lg-8"}>
                        {this.props.faqs.map(function(faqItem, i){
                            return (<FaqCard
                                key={"faqItem-"+i}
                                cardId={i}
                                question={faqItem.heading}
                                answer={faqItem.text}
                            />);}

                        )}
                    </div>
                </div>
            </div>
        )
    }
}



const mapStateToProps = (state) => {
    return {
        faqs : state.faq.faqs
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getFaqs: () => {dispatch (Actions.getFaqs())},
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);

