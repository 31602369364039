import React, {Component} from 'react';
import {connect} from "react-redux";

import * as Actions from "../actions/RedeemNowActions";
import 'RedeemNow/css/RedeemNow.scss'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

// import { config } from 'config.js';

class CharityCard extends Component {

    constructor(props){
        super(props);
        this.state = {
            modal: false,
            isCardFlipped: false
        };
        this.toggle = this.toggle.bind(this);
        this.handleHideModal = this.handleHideModal.bind(this);
        this.submitRedeemNowCharitySelection = this.submitRedeemNowCharitySelection.bind(this);
    }

    submitRedeemNowCharitySelection = () => {

        console.log("submitRedeemNowCharitySelection:");
        this.props.submitRedeemNowCharitySelection(
            this.props.id,
            this.props.redemptionAmount,
            this.props.redemption,
            () => {
                console.log("submitRedeemNowCharitySelection: callback");
                this.props.advanceToNextRedeemNowStep(this.props.currentRedeemNowStep)}
        );
    };

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    handleHideModal(){
        this.setState({modal: false})
    };
    handleShowModal(){
        this.setState({modal: true})
    };

    showImage(){
        if (this.props.image){
            return (
                <div className={"charity-card-image-container text-center"}>
                    <img src={this.props.image} alt="organization logo" className={"card-image charity-card-image"}/>
                </div>
            )
        } else {
            return <div className={"charity-card-image-container"}>&nbsp;</div>;
        }
    }

    toggleCard = (id) => {
        console.log("clicked info");
        console.log("id: " + id);

        this.setState((state, props) => ({
            isCardFlipped  : !this.state.isCardFlipped
        }));

    };

    urlButton(){
        if (this.props.url && this.props.urlText && this.props.url !== "" && this.props.urlText !== ""){
            return (<div className="row text-center mt-3">
                <div className="col-12">
                    <a href={this.props.url} target="_blank" rel="noopener noreferrer"
                       type="button"
                       className={"btn btn-primary pointer charity-card-button w-100"}
                    >
                        {this.props.urlText}
                    </a>
                </div>
            </div>);
        }
        return null;
    };

    render() {



        return (
            <div id={"cardFlip" + this.props.id} className={ "charity-card-container col-12 col-md-6 col-lg-4 col-xl-3 " + (this.state.isCardFlipped ? "card-flipped" : "card-not-flipped") } >
                {/*front of the card*/}
                <div id={ this.props.id + "front" }
                         className={"front charity-card card w-100 " + (this.state.isCardFlipped ? "display-none" : "")}  >
                    <div className={"card-body text-left"}>

                        <div className="row">
                            <div className="col-12">
                                {this.showImage()}
                            </div>
                        </div>

                        {/*<div className="row mt-2">
                            <div className="col-12 description-short">
                                {this.props.description}
                            </div>
                        </div>*/}

                        <div className="row text-center mt-3">
                            <div className="col-12">
                                <button
                                    type="button" id={"cardDescription" + this.props.id}
                                    onClick={() => this.toggleCard() }
                                    className="more-info-button pointer btn btn-light charity-card-button"
                                >
                                    learn more
                                </button>
                            </div>
                        </div>
                        <div className="row text-center mt-2">
                            <div className="col-12">
                                <button
                                    type="button"
                                    className={"btn btn-success pointer charity-card-button"}
                                    disabled = {this.props.redeemNow.isPreview}
                                    onClick={() => {
                                        if ( !this.props.redeemNow.isPreview ){
                                            this.handleShowModal();
                                        }
                                    }
                                    }
                                >
                                    Donate to<br/><b>{this.props.name}</b>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

                {/*back of the card*/}
                <div id={ this.props.id + "back" }
                        className={"back charity-card card light-opacity w-100 " + (!this.state.isCardFlipped ? "display-none" : "")}  >
                    <div className={"card-body text-left"} >

                        <div className="row">
                            <div className="col-6">
                                <h6 className={"text-left charity-card-header"}> {this.props.name} </h6>
                            </div>
                            <div className="col-6">
                                <button
                                    type="button"
                                    className={"btn btn-light pointer charity-card-button float-right"}
                                    onClick={ () => this.toggleCard() }
                                >
                                    back
                                </button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 description-long">
                                {this.props.description}
                            </div>
                        </div>

                        {this.urlButton()}

                    </div>
                </div>


                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalBody>
                        Are you sure you would like to donate to {this.props.name}?
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <Button color="success" onClick={() => {
                                console.log("clicked: " + this.props.id );
                                if ( this.props.redeemNow.redemption.order ){
                                    this.submitRedeemNowCharitySelection();
                                } else {
                                    console.log("Error submitting charity selection.")
                                }

                            }
                            }>Yes, I choose {this.props.name}</Button>{' '}
                            <Button color="secondary" onClick={this.handleHideModal}>Cancel</Button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}



const mapStateToProps = (state) => {
    return {
        redeemNow : state.redeemNow,
        currentRedeemNowStep : state.redeemNow.redeemNowStep,
        redemption : state.redeemNow.redemption,
        redemptionAmount : state.redeemNow.redemption.amount,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        advanceToNextRedeemNowStep : (currentRedeemNowStep) => dispatch(Actions.advanceToNextRedeemNowStep(currentRedeemNowStep)),
        submitRedeemNowCharitySelection : (charitySelectionId, redemptionAmount, redemption, currentRedeemNowStep) => dispatch(Actions.submitRedeemNowCharitySelection(charitySelectionId, redemptionAmount, redemption, currentRedeemNowStep))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(CharityCard);

