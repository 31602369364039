import React, {Component} from 'react';
import {connect} from "react-redux";

class FaqCard extends Component {

    render() {
        return (
            <div className={"m-5"}>
                <div className={"card"}>
                    <div className={"card-header text-left clickable"} data-toggle="collapse"
                         data-target={".faq-details-" + this.props.cardId} role="button">
                        <span className={"toggle-icon-container"}>
                            <span className={"faq-details-" + this.props.cardId + " plus-toggle-icon collapse"}>
                                +
                            </span>
                            <span className={"faq-details-" + this.props.cardId + " minus-toggle-icon collapse"}>
                                -
                            </span>
                        </span>
                        <span>
                            {this.props.question}
                        </span>
                    </div>
                    <div className={"faq-details-" + this.props.cardId + " collapse card-body text-left"}>
                        <div dangerouslySetInnerHTML={{ __html: this.props.answer }} />
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};


export default connect(mapStateToProps, mapDispatchToProps)(FaqCard);

