import { config } from 'config.js';

export const SUBMIT_DONATION_REQUEST_START = "SUBMIT_DONATION_REQUEST_START";
export const SUBMIT_DONATION_REQUEST_END = "SUBMIT_DONATION_REQUEST_END";
export const UPDATE_DONATION_ID = "UPDATE_DONATION_ID";
export const UPDATE_DONATION_PAYPAL_DATA_START = "UPDATE_DONATION_PAYPAL_DATA_START";
export const UPDATE_DONATION_PAYPAL_DATA_END = "UPDATE_DONATION_PAYPAL_DATA_END";
export const UPDATE_DONATE_MESSAGE = "UPDATE_DONATE_MESSAGE";
export const UPDATE_DONATE_AMOUNT = "UPDATE_DONATE_AMOUNT";
export const TOGGLE_CUSTOM_AMOUNT_ENABLED = "TOGGLE_CUSTOM_AMOUNT_ENABLED";

export function startDonation(message, amount, payPalData, successCallback, failureCallback) {
    return function(dispatch){

        if ( !amount){
            console.log("no amount...");
            return;
        }

        let requestBody = {
            message : message,
            amount: amount
        };

        dispatch({type:"SUBMIT_DONATION_REQUEST_START"});

        console.log("sending donation request:");
        console.log(requestBody);

        /*start request*/
        fetch(config.apiUrl + config.urls.donation, {
            method: "POST",
            body: JSON.stringify(requestBody),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((response) => { return response.json(); } )
        .then((responseJson) => {
                console.log("success response");
                console.log(responseJson);

                /*update the donation id*/
                if ( responseJson.id ){
                    dispatch({type:"UPDATE_DONATION_ID", value: responseJson.id });
                }
                return responseJson;
        }).then(() => {
            console.log("successCallback");
            successCallback();

        }).then(() => {
            console.log("dispatching SUBMIT_DONATION_REQUEST_END");
            dispatch({type:"SUBMIT_DONATION_REQUEST_END"});
        }).catch((error) => {
            console.log("error response");
            console.error(error);
            failureCallback();
            dispatch({type:"SUBMIT_DONATION_REQUEST_END"});
        });

    };

}


export function submitDonationPaypalData(donationId, payPalData, successCallback) {

    return function(dispatch){

        console.log("submitDonationPaypalData saving payPalData");
        console.log(payPalData);

        let requestBody = {
            "payPalId":payPalData.id,
            "intent":payPalData.intent,
            "state":payPalData.state,
            "cart":payPalData.cart,
            "createTime":payPalData.createTime,
            "transactions":payPalData.transactions
        };

        if ( payPalData.payer && payPalData.payer.payer_info) {
            requestBody.payerEmail = payPalData.payer.payer_info.email;
            requestBody.payerFirstName = payPalData.payer.payer_info.first_name;
            requestBody.payerLastName = payPalData.payer.payer_info.last_name;
            requestBody.payerId = payPalData.payer.payer_info.payer_id;
        }

        dispatch({type:"UPDATE_DONATION_PAYPAL_DATA_START"});

        /*start request*/

        let orderUrl = config.apiUrl + config.urls.donation + "/" + donationId + "/paypalData";
        fetch( orderUrl, {
            method: "POST",
            body: JSON.stringify(requestBody),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(() => {
                successCallback();
            })
            .catch((error) => {
                console.log("error response");
                console.error(error);
            });

        dispatch({type:"UPDATE_DONATION_PAYPAL_DATA_END"});

    };

}