import { config } from 'config.js';

export const FUN_IDEAS_UPDATE = "FUN_IDEAS_UPDATE";

const debugFunIdeas = [
    {"id":4,"heading":"Fun 5 Title","text":"use kidsgiving tokens for wheels! This race car was made with a toilet paper roll, paint and glue","iconUrl":"/images/sunsetpeacesigns.jpg","sortOrder":"5"},
    {"id":5,"heading":"Fun 2 Title","text":"use kidsgiving tokens for wheels! This race car was made with a toilet paper roll, paint and glue","iconUrl":"/images/sunsetpeacesigns.jpg","sortOrder":"2"},
    {"id":6,"heading":"Fun 4 Title","text":"Fun Idea 4 Text","iconUrl":"/images/cow.png","sortOrder":"4"},
    {"id":7,"heading":"Fun 3 Title","text":"use kidsgiving tokens for wheels! This race car was made with a toilet paper roll, paint and glue","iconUrl":"/images/cow.png","sortOrder":"3"}
];

export function getFunIdeas() {
    return function(dispatch) {
        fetch( config.apiUrl + config.urls.funIdeas )
            .then((response) => {
                console.log("response");
                console.log(response);
                return response.json();
            })
            .then((responseJson) => {
                console.log("success response, updating fun ideas");
                console.log(responseJson);
                /*sortFunIdeas*/
                responseJson.sort(function(a,b){
                    return (a.sortOrder - b.sortOrder);
                });

                return dispatch({type: FUN_IDEAS_UPDATE, funIdeas: responseJson});

            })
            .catch((error) => {
                console.log("error response");
                console.error(error);
                console.log("filling card types with debug data");
                /*sortFaqs*/
                debugFunIdeas.sort(function(a,b){
                    return (a.sortOrder - b.sortOrder);
                });
                return dispatch({type: FUN_IDEAS_UPDATE, funIdeas: debugFunIdeas});

            });
    }
}

