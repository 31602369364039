import React, { Component } from 'react';
import {connect} from "react-redux";

import 'site/css/InputField.css'

class InputField extends Component {

    displayError = (error) => {
        if ( error && error !== ""){
            return (
                <div className="input-group below-input-container">
                    <div className={"offset-1 col-11 helper-text text-left"}>
                        <div className={"row"}>
                            <span className={"m-1 ml-4 p-0 input-error-message"}>{error}</span>
                        </div>
                    </div>
                </div>
            );
        }
    };

    displayHelperText = () => {
        if( !this.props.hideHelperText ){
            return (
                <div className="input-group below-input-container">
                    <div className={"offset-1 col-11 helper-text text-left"}>
                        <div className={"row m-1"}>
                            {this.props.helperText}
                        </div>
                    </div>
                </div>
            );
        }
    };

    displayInfoPopup = (text) => {
        if ( text && text !== ""){
            return (
                <div title={this.props.helperText} className={"info-popup"}>
                    <i className="fas fa-info-circle" ></i>
                </div>
            );
        } else { return (
            <div className={"info-popup"}></div>
        );}
    };

    displayLabel = (text) => {
        if ( text && text !== ""){
            return (
                <div className="input-group-prepend col-5 col-sm-4 pr-0 ">
                    <span className="input-group-text input-label-element justify-content-end input-field-label" >{text}</span>
                </div>
            );
        }
    };

    render() {
        return(
            <div>
                <div className="input-group">
                    {this.displayLabel(this.props.label)}
                    <textarea
                           className={"form-control " + this.props.textAreaClass }
                           value={this.props.value ? this.props.value : ""}
                           autoComplete={this.props.autoComplete ? this.props.autoComplete : ""}
                           aria-label="Leave a note" aria-describedby="inputGroup-sizing-default"
                           disabled={this.props.disabled}
                           onChange={(e) => this.props.onChange(e)}/>
                </div>
                    {this.displayHelperText()}
                    {this.displayError(this.props.error)}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};


export default connect(mapStateToProps, mapDispatchToProps)(InputField);


