import { config } from 'config.js';

/*workflow*/
export const SET_REDEEM_NOW_STEP = "SET_REDEEM_NOW_STEP";
export const SET_CATEGORY_ID = "SET_CATEGORY_ID";

/*redemptionPreview*/
export const GIFT_CARD_INFO_CHANGE_REDEMPTION_CATEGORIES = "GIFT_CARD_INFO_CHANGE_REDEMPTION_CATEGORIES";
export const GIFT_CARD_INFO_CHANGE_REDEMPTION_ORGANIZATIONS = "GIFT_CARD_INFO_CHANGE_REDEMPTION_ORGANIZATIONS";
export const UPDATE_REDEEM_NOW_CODE = "UPDATE_REDEEM_NOW_CODE";
export const UPDATE_REDEEM_NOW_CAPTCHA = "UPDATE_REDEEM_NOW_CAPTCHA";
export const START_SUBMIT_REDEEM_NOW_CODE = "START_SUBMIT_REDEEM_NOW_CODE";
export const REDEEM_NOW_UPDATE_REDEMPTION = "REDEEM_NOW_UPDATE_REDEMPTION";
export const SUBMITTED_REDEEM_NOW_CODE = "SUBMITTED_REDEEM_NOW_CODE";
export const UPDATE_PREVIEW_STATE = "UPDATE_PREVIEW_STATE";


export const REDEEM_CHARITY_STARTED = "REDEEM_CHARITY_STARTED";
export const REDEEM_CHARITY_SUCCESS = "REDEEM_CHARITY_SUCCESS";
export const REDEEM_CHARITY_SUCCESS_DEBUG = "REDEEM_CHARITY_SUCCESS_DEBUG";
export const REDEEM_CHARITY_ERROR = "REDEEM_CHARITY_ERROR";

const debugRedemptionCategories = [
    {
        "id":2,
        "sortOrder":1,
        "descriptiveText":"help to preserve animals in danger of extinction, and to protect and rescue animals in abusive situations",
        "imageUrl":"/images/cow.png",
        "mobileBannerImageUrl":null,
        "bannerImageUrl":null,
        "categoryName":"Protect Animals"
    },
    {
        "id":3,
        "sortOrder":2,
        "descriptiveText":"help sick and injured children and their families",
        "imageUrl":"/images/hand.png",
        "mobileBannerImageUrl":null,
        "bannerImageUrl":null,
        "categoryName":"Help Children"
    },
    {
        "id":4,
        "sortOrder":3,
        "descriptiveText":"help people who are fleeing from violence and injustice in their country",
        "imageUrl":"/images/dove.png",
        "mobileBannerImageUrl":null,
        "bannerImageUrl":null,
        "categoryName":"Promote Peace"
    },
    {
        "id":5,
        "sortOrder":4,
        "descriptiveText":"help to protect clean air and water all over the world",
        "imageUrl":"/images/globe.png",
        "mobileBannerImageUrl":null,
        "bannerImageUrl":null,
        "categoryName":"Save the Environment"
    }
];

const debugRedemptionOrganizations = [

    {"id":1,"active":true,"organizationName":"Cash4KoolKidz","organizationImageUrl":"/images/cash4kidz.jpg","organizationDescription":"Doing the most good for only the coolest kids","organizationType":
            {"id":3,"sortOrder":2,"descriptiveText":"help sick and needy children and their families","imageUrl":"/images/hand.png","mobileBannerImageUrl":null,"bannerImageUrl":null,"categoryName":"Help Children"}},
    {"id":2,"active":true,"organizationName":"Big Cat Rescue","organizationImageUrl":null,"organizationDescription":"sample for Big Cat Rescue; really big cats!","organizationType":
            {"id":2,"sortOrder":1,"descriptiveText":"help to preserve animals in danger of extinction, and to protect and rescue animals in abusive situations","imageUrl":"/images/cow.png","mobileBannerImageUrl":null,"bannerImageUrl":null,"categoryName":"Protect Animals"}},
    {"id":3,"active":true,"organizationName":"IRIS","organizationImageUrl":null,"organizationDescription":"Integrated Refugee & Immigrant Services","organizationType":
            {"id":4,"sortOrder":3,"descriptiveText":"help people who are fleeing from violence and promote safe and just communities","imageUrl":"/images/dove.png","mobileBannerImageUrl":null,"bannerImageUrl":null,"categoryName":"Promote Peace"}},
    {"id":4,"active":false,"organizationName":"Tara Farm Rescue","organizationImageUrl":null,"organizationDescription":"Animal Sanctuary for ailing or unloved farm animals.  This is a long description. Animal Sanctuary for ailing or unloved farm animals.  This is a long description ","organizationType":
            {"id":2,"sortOrder":1,"descriptiveText":"help to preserve animals in danger of extinction, and to protect and rescue animals in abusive situations","imageUrl":"/images/cow.png","mobileBannerImageUrl":null,"bannerImageUrl":null,"categoryName":"Protect Animals"}},
    {"id":5,"active":true,"organizationName":"Make a Wish CT","organizationImageUrl":null,"organizationDescription":"Make-A-Wish Foundation of Connecticut","organizationType":
            {"id":3,"sortOrder":2,"descriptiveText":"help sick and needy children and their families","imageUrl":"/images/hand.png","mobileBannerImageUrl":null,"bannerImageUrl":null,"categoryName":"Help Children"}},
    {"id":6,"active":true,"organizationName":"CT Fund for the Environment","organizationImageUrl":null,"organizationDescription":"Connecticut Fund for the Environment","organizationType":
            {"id":5,"sortOrder":4,"descriptiveText":"help to protect clean air and water all over the world","imageUrl":"/images/globe.png","mobileBannerImageUrl":null,"bannerImageUrl":null,"categoryName":"Save the Environment"}},
    {"id":7,"active":true,"organizationName":"The Fred Rogers Company","organizationImageUrl":"/images/hand.png","organizationDescription":"Mr Rogers org.. Mr Rogers orgMr Rogeers orgMr Rogers orgMr . Mr Rogers orgMr Rogers orgMr Rogers orgMr Rogers orgMr . Mr Rogers orgMr Rogers orgMr ers orgMr Rogers orgMr . Mr Rogers orgMr Rogers orgMr Rogers orgMr Rogers orgMr . Mr Rogers orgMr Rogers orgMr rs orgMr Rogers orgMr Rogers orgMr . Mr Rogers orgMr Rogers orgMr Rogers orgMr Rogers orgMr . Mr Rogers orgMr Rogers orgMr Rogers orgMr Rogers orgMr . Mr Rogers orgMr Rogers orgMr Rogers orgMr Rogers orgMr . Mr Rogers orgMr Rogers orgMr Rogers orgMr Rogers orgMr . Mr Rogers orgMr Rogers orgMr Rogers orgMr Rogers orgMr Rogers orgMr Rogers orgMr Rogers orgMr Rogers org","organizationType":
            {"id":3,"sortOrder":2,"descriptiveText":"help sick and needy children and their families","imageUrl":"/images/hand.png","mobileBannerImageUrl":null,"bannerImageUrl":null,"categoryName":"Help Children"}},
    {"id":8,"active":true,"organizationName":"This is a new organization","organizationImageUrl":"/images/rawpixel-668354-unsplash.jpg","organizationDescription":"this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description.this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description.this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description.this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. this is a description. ","organizationType":
            {"id":3,"sortOrder":1,"descriptiveText":"help sick and needy children and their families","imageUrl":"/images/rawpixel-668354-unsplash.jpg","mobileBannerImageUrl":null,"bannerImageUrl":null,"categoryName":"Help Children"}}
];

const debugFailureRedemption  = "Invalid or expired redemption code";

export function submitRedeemNowCode(redeemNowCode, captcha, onSuccess, onFailure) {
    console.log("send code: " + redeemNowCode);
    console.log("send captcha: " + captcha);

    return ( dispatch ) => {

        let requestBody = {
            redemptionCode: redeemNowCode,
            captcha: captcha
        };

        dispatch( {type:"START_SUBMIT_REDEEM_NOW_CODE"} );

        /*start request*/
        fetch( config.apiUrl + config.urls.redeemNow, {
            method: "POST",
            body: JSON.stringify(requestBody),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then( (response) => { return response.json(); } )
        .then( (responseJson) => {
            console.log("success response");
            console.log(responseJson);
            if ( validateCodeEntryResponse(responseJson) ){
                dispatch({ type: REDEEM_NOW_UPDATE_REDEMPTION, redemption: responseJson });
                onSuccess();
            } else if ( debugFailureRedemption === "Invalid or expired redemption code" ) {
                onFailure("uh-oh!  that code didn't look right.  try again?");
            } else {
                onFailure("something went wrong.. please try again later");
            }

        })
        .catch( (error) => {
            console.log("error response");
            console.error(error);

            if( config.debug ){
                if ( validateCodeEntryResponse(debugFailureRedemption) ){
                    console.log("setting debug values");
                    dispatch({ type: REDEEM_NOW_UPDATE_REDEMPTION, redemption: debugFailureRedemption });
                    onSuccess();
                } else if ( debugFailureRedemption === "Invalid or expired redemption code" ) {
                    onFailure("uh-oh!  that code didn't look right.  try again?");
                } else {
                    onFailure("something went wrong.. please try again later");
                }

            }

            onFailure("something went wrong.. please try again later");
        });

        dispatch( {type: "SUBMITTED_REDEEM_NOW_CODE" } );
    }
}

function validateCodeEntryResponse(responseJson){
    return ( responseJson.id && responseJson.sessionId );
}


export function updatePreviewState(isPreview, onSuccess) {
    return ( dispatch ) => {
        dispatch({ type: UPDATE_PREVIEW_STATE, isPreview: isPreview });
        if (onSuccess) {
            onSuccess();
        }
    }
}

export function advanceToNextRedeemNowStep(currentStep) {
    return {type: SET_REDEEM_NOW_STEP, redeemNowStep: (currentStep + 1) }
}

export function previousRedeemNowStep(currentStep) {
    let nextStep = currentStep > 1 ? currentStep - 1 : 1;
    return {type: SET_REDEEM_NOW_STEP, redeemNowStep: (nextStep) }
}

export function setCategory(categoryId){
    return {type: SET_CATEGORY_ID, categoryId: categoryId }
}

export function getRedemptionCategories() {
    return function(dispatch) {
        fetch( config.apiUrl + config.urls.redemptionCategories )
            .then((response) => {
                return response.json();
            })
            .then((redemptionCategories) => {
                redemptionCategories.sort( (a, b ) => {
                    return a.sortOrder-b.sortOrder;
                });
                return dispatch({type: GIFT_CARD_INFO_CHANGE_REDEMPTION_CATEGORIES, redemptionCategories: redemptionCategories});

            })
            .catch((error) => {
                console.log("error response");
                console.error(error);
                console.log("filling redemption categories with debug data");
                debugRedemptionCategories.sort( (a, b ) => {
                    return a.sortOrder-b.sortOrder;
                });
                return dispatch({type: GIFT_CARD_INFO_CHANGE_REDEMPTION_CATEGORIES, redemptionCategories: debugRedemptionCategories});

            });
    }
}

export function getRedemptionOrganizations() {
    return function(dispatch) {
        fetch( config.apiUrl + config.urls.redemptionOrganizations )
            .then((response) => {
                return response.json();
            })
            .then((redemptionOrganizations) => {
                return dispatch({type: GIFT_CARD_INFO_CHANGE_REDEMPTION_ORGANIZATIONS, redemptionOrganizations: redemptionOrganizations});

            })
            .catch((error) => {
                console.log("error response");
                console.error(error);
                console.log("filling redemption organization with debug data");
                return dispatch({type: GIFT_CARD_INFO_CHANGE_REDEMPTION_ORGANIZATIONS, redemptionOrganizations: debugRedemptionOrganizations});

            });
    }
}

export function submitRedeemNowCharitySelection( charitySelectionId, redemptionAmount, redemption, onSuccess ){

    return function(dispatch) {

        dispatch( {type: "REDEEM_CHARITY_STARTED" } );

        let requestBody = redemption;

        requestBody.selection = [{
            redemptionOrgId: charitySelectionId,
            amount: redemptionAmount
        }];

        /*start request*/
        fetch( config.apiUrl + config.urls.redeemNow, {
            method: "PUT",
            body: JSON.stringify(requestBody),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            console.log("redemption organization response to put");
            if ( response.status === 200 ){
                console.log("success response, next page");
                dispatch({type: "REDEEM_CHARITY_SUCCESS" } );
                dispatch ({type: "UPDATE_REDEEM_NOW_CODE", value : ""});
                onSuccess();
            } else {
                errorResponseRedeemCharity(dispatch, onSuccess);
            }
        })
        .catch((error) => {
            console.log("error response");
            console.error(error);
            errorResponseRedeemCharity(dispatch, onSuccess);

        });
    };

}

const errorResponseRedeemCharity = (dispatch, onSuccess) => {

    console.log("did not successfully return.");

    if ( config.debug ){
        console.log("DEBUG Success processing");
        dispatch({type: "REDEEM_CHARITY_SUCCESS_DEBUG" } );
        onSuccess();

    } else {
        return dispatch({type: "REDEEM_CHARITY_ERROR" } );
    }
};