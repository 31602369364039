import React, { Component } from 'react';
import {connect} from "react-redux";

import 'RedeemNow/css/RedeemNow.scss'

class CategoryDisplay extends Component {

    displayText = function(side){
        return (
            <div className={ "text-" + (side==="left"? "left ": "right ") + "category-text"}>
                {this.props.text}
            </div>
        );
    };

    displayImage = function(){
        return (
            <div className={"justify-content-center"}>
                <img src={this.props.image} className={"w-100"} alt={"category display"}/>
            </div>
        );
    };

    render() {

        let side = this.props.sortOrder % 2 === 1 ? "left" : "right";

        return(
            <div className={"col-md-6  mt-4 " + ((side === "left") ? "pl-4" : "pr-4") + (!this.props.isPreview ? " category-container" : "")  } onClick={() => this.props.onClick(this.props.categoryId)}>
                <div className={"row justify-content-center mt-3"} >
                    <h5 className={"category-title"}>{this.props.title}</h5>
                </div>
                <div className="row">
                    <div className={ side === "left" ? "col-sm-4 " : "col-sm-8 " }>
                        {(side === "left") ? this.displayImage() : this.displayText() }
                    </div>
                    <div className={ side === "left" ? "col-sm-8 " : "col-sm-4 " }>
                        {(side === "left") ? this.displayText(side) : this.displayImage() }
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};


export default connect(mapStateToProps, mapDispatchToProps)(CategoryDisplay);


