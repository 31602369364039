import React, {Component} from 'react';
import {connect} from "react-redux";

import * as Actions from "../actions/RedeemNowActions";

import 'RedeemNow/css/RedeemNow.scss'
import heart from 'images/heart.jpg';
import token from 'images/kidsgiving-token.png';
import {push} from "react-router-redux";


class RedeemNowThankYou extends Component {

    componentDidMount() {
        window.scrollTo(0,0);
    }



    render() {
        return (
            <div className={"w-100 heart-background"} >
                <div className={"row mt-5 mx-0"}>
                    <div className={"col-12 offset-lg-3 col-lg-6 offset-md-2 col-md-8 card card-body"}>
                        <div className={"row justify-content-center"}>
                            <h1 className={"redeem-now-thank-you-header"}>congratulations!</h1>
                        </div>
                        <div className={"row m-2"}>
                            <div className={"col-12"}>
                                <span className={"redeem-now-thank-you-text"}>a donation has just been made in your name, {this.props.redeemNow.recipientName}</span>
                            </div>
                        </div>
                        <div className={"row m-2"}>
                            <div className={"col-12"}>
                                <img src={heart} className={"heart-image"} alt={"heart"}/>
                            </div>
                        </div>

                        <div className={"row m-2"}>
                            <div className={"col-12"}>
                                <span className={"redeem-now-thank-you-text"}>thank you from the bottom of all our hearts!</span>
                            </div>
                        </div>
                    </div>


                </div>

                <div className={"row mt-3 mx-0"}>
                    <div className={"col-12 offset-lg-2 col-lg-8 offset-md-1 col-md-10"}>
                        <div className={"row align-items-center m-4"}>
                            <div className={"col-sm-6 offset-sm-3 p-4 align-items-center"}>
                                <div    className={" card card-body clickable-card thank-you-lower-card"}
                                        onClick={() => this.props.changePageToFunIdeas()} >
                                    <div className={"m-auto"}>
                                        <div className={"row align-items-center h-100"}>
                                            <div className={"col-12 col-md-6 h-100"}>
                                                <img src={token} className={"w-100"} alt={"token"}/>
                                            </div>
                                            <div className={"col-12 col-md-6 align-middle "}>
                                                <div className={"m-auto"}>
                                                    fun ideas
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        redeemNow : state.redeemNow,
        currentRedeemNowStep : state.redeemNow.redeemNowStep,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        advanceToNextRedeemNowStep : (currentRedeemNowStep) => dispatch(Actions.advanceToNextRedeemNowStep(currentRedeemNowStep)),
        changePageToFunIdeas : () => dispatch(push("/fun-ideas")),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(RedeemNowThankYou );

