import * as Actions from "../actions/GiftNowActions";

import {config} from 'config.js';

const debugState = {
    giftNowStep: 2,
    isLoading : true,
    hasError : false,
    paymentComplete: false,
    giftCardInfo: {
        purchaserName: "Lauren",
        recipientName: "WWWWWWWWWWWWWWWWWWWWW",
        greetingId: 1,
        customGreetingEnabled: true,
        customGreetingText: "this is a custom greeting text",
        email: "testthisalongemailwithalotofcharacters@kidsgiving.us",
        amount: 40,
        customAmountEnabled: false,
        donationEnabled: true,
        donationAmount: 1.00,
        lineItems: [],
        greetingFontSize: 14,
        greetingLineHeight: 26,
        orderInfo: {
            orderId: null,
            orderIdentifier: null
        },
    },
    errors: {},
    greetings: [{
        id: 1,
        name: "justBecause",
        customText: false,
        anonymous: false,
        publicFacingName: "Just Because",
        header: "For {recipient_name},\nJust Because",
        line1: "{purchaser_name} has given you the gift of giving!",
        line2: "lift the keepsake to reveal your gift code",
        line3: "then go to kidsgiving.us/kidspage and choose how to make a difference in the world"
    }],
    customGreeting: {
        header: "Congrats Steelsen--",
        line2: "lift the keepsake to reveal your gift code",
        line3: "then go to kidsgiving.us/kidspage and choose how to make a difference in the world"
    },
    shipToInfo: {
        firstName: "nate",
        lastName: "whittemore",
        address1: "address line 1",
        address2: "address line 2",
        city: "hartford",
        state: "ct",
        zipcode: "06106",
        country: "United States"
    },
    shippingInfo: {
        initialCost: null,
        shippingMethodId: null,
        tracked: false
    },
    authorizationCodes: ["test", "kidsgiving"]
};


const initialState = {
    giftNowStep: 1,
    isLoading : true,
    hasError : false,
    paymentComplete: false,
    giftCardInfo: {
        purchaserName: "",
        recipientName: "",
        greetingId: null,
        email: "",
        amount: null,
        customGreetingEnabled: false,
        customGreetingText: "",
        customAmountEnabled: false,
        donationEnabled: true,
        donationAmount: 1.00,
        lineItems: [],
        orderInfo: {
            orderId: null,
            orderIdentifier: null
        },
    },
    errors: {},
    greetings: [],
    customGreeting: {
        line2: "lift the keepsake to reveal your gift code",
        line3: "then go to kidsgiving.us/kidspage and choose how to make a difference in the world"
    },
    shipToInfo: {
        firstName: null,
        lastName: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zipcode: null,
        country: "United States"
    },
    shippingInfo: {
        initialCost: null,
        shippingMethodId: null,
        tracked: false
    },
    shippingMethods: [],
    authorizationCode: ""
};


export default function GiftNowReducer(state = config.debug ? debugState : initialState, action) {

    let newObject = {};

    switch (action.type) {

        case Actions.RESET_TO_INITIAL_STATE:
            return Object.assign({}, initialState);
        case Actions.SET_GIFT_NOW_STEP:
            return Object.assign({}, state, {giftNowStep: action.giftNowStep});
        case Actions.UPDATE_GIFT_CARD_ERRORS:
            return Object.assign({}, state, {errors: action.value});
        case Actions.GIFT_CARD_INFO_ERROR:
            return Object.assign({}, state, {hasError: action.hasError});

        /*step 1, gift card info*/
        case Actions.GIFT_CARD_INFO_GET_CARDTYPES:
            return Object.assign({}, state, {
                giftCardInfo: state.giftCardInfo,
                greetings: action.cardtypes,
                isCardTypesLoaded : action.isCardTypesLoaded,
                hasError : action.hasError});

        case Actions.GIFT_CARD_INFO_CHANGE_PURCHASER_NAME:
            newObject = Object.assign({}, state.giftCardInfo, {purchaserName: action.value});
            return Object.assign({}, state, {giftCardInfo: newObject});
        case Actions.GIFT_CARD_INFO_CHANGE_RECIPIENT_NAME:
            newObject = Object.assign({}, state.giftCardInfo, {recipientName: action.value});
            return Object.assign({}, state, {giftCardInfo: newObject});

        case Actions.GIFT_CARD_INFO_CHANGE_GREETING_ID:
            newObject = Object.assign({}, state.giftCardInfo, {greetingId: parseInt(action.value, 10)});
            return Object.assign({}, state, {giftCardInfo: newObject});
        case Actions.GIFT_CARD_INFO_SET_CUSTOM_GREETING_ENABLED:
            newObject = Object.assign({}, state.giftCardInfo, {customGreetingEnabled: action.value});
            return Object.assign({}, state, {giftCardInfo: newObject});
        case Actions.GIFT_CARD_INFO_SET_DONATION_ENABLED:
            newObject = Object.assign({}, state.giftCardInfo, {donationEnabled: action.value});
            return Object.assign({}, state, {giftCardInfo: newObject});
        case Actions.GIFT_CARD_INFO_SET_CUSTOM_GREETING_TEXT:
            newObject = Object.assign({}, state.giftCardInfo, {customGreetingText: action.value});
            return Object.assign({}, state, {giftCardInfo: newObject});
        case Actions.GIFT_CARD_INFO_SET_GREETING_FONT_SIZE:
            newObject = Object.assign({}, state.giftCardInfo, {
                greetingFontSize: action.value,
                greetingLineHeight: action.value + 12
            });
            return Object.assign({}, state, {giftCardInfo: newObject});


        case Actions.GIFT_CARD_INFO_CHANGE_EMAIL:
            newObject = Object.assign({}, state.giftCardInfo, {email: action.value});
            return Object.assign({}, state, {giftCardInfo: newObject});
        case Actions.GIFT_CARD_INFO_CHANGE_AMOUNT:
            if (action.value) {
                action.value = Number(action.value.replace(/[^0-9.]/g, '')).toFixed(2);
            }
            newObject = Object.assign({}, state.giftCardInfo, {amount: action.value});
            return Object.assign({}, state, {giftCardInfo: newObject});
        case Actions.GIFT_CARD_INFO_TOGGLE_CUSTOM_AMOUNT:
            newObject = Object.assign({}, state.giftCardInfo, {customAmountEnabled: !action.value});
            return Object.assign({}, state, {giftCardInfo: newObject});
        case Actions.GIFT_CARD_INFO_AUTHORIZATION_CODE:
            return Object.assign({}, state, {authorizationCode: action.value});

        /*step 1 ship to info*/
        case Actions.SHIP_TO_INFO_CHANGE_FIRST_NAME:
            newObject = Object.assign({}, state.shipToInfo, {firstName: action.value});
            return Object.assign({}, state, {shipToInfo: newObject});
        case Actions.SHIP_TO_INFO_CHANGE_LAST_NAME:
            newObject = Object.assign({}, state.shipToInfo, {lastName: action.value});
            return Object.assign({}, state, {shipToInfo: newObject});
        case Actions.SHIP_TO_INFO_CHANGE_ADDRESS1:
            newObject = Object.assign({}, state.shipToInfo, {address1: action.value});
            return Object.assign({}, state, {shipToInfo: newObject});
        case Actions.SHIP_TO_INFO_CHANGE_ADDRESS2:
            newObject = Object.assign({}, state.shipToInfo, {address2: action.value});
            return Object.assign({}, state, {shipToInfo: newObject});
        case Actions.SHIP_TO_INFO_CHANGE_CITY:
            newObject = Object.assign({}, state.shipToInfo, {city: action.value});
            return Object.assign({}, state, {shipToInfo: newObject});
        case Actions.SHIP_TO_INFO_CHANGE_STATE:
            newObject = Object.assign({}, state.shipToInfo, {state: action.value});
            return Object.assign({}, state, {shipToInfo: newObject});
        case Actions.SHIP_TO_INFO_CHANGE_ZIPCODE:
            newObject = Object.assign({}, state.shipToInfo, {zipcode: action.value});
            return Object.assign({}, state, {shipToInfo: newObject});
        case Actions.SHIP_TO_INFO_CHANGE_COUNTRY:
            newObject = Object.assign({}, state.shipToInfo, {country: action.value});
            return Object.assign({}, state, {shipToInfo: newObject});

        /*step 2 shipping info*/
        case Actions.SHIPPING_INFO_CHANGE_SHIPPING_METHOD_ID:
            newObject = Object.assign({}, state.shippingInfo, {shippingMethodId: parseInt(action.value, 10)});
            return Object.assign({}, state, {shippingInfo: newObject});
        case Actions.SHIPPING_INFO_CHANGE_SHIPPING_METHODS:
            return Object.assign({}, state, {
                shippingMethods: action.shippingMethods,
                isLoading : action.isLoading
            });

        /*step 2.5: update order info*/
        case Actions.UPDATE_ORDER_ID:
            newObject = Object.assign({}, state.giftCardInfo, {orderId: action.value});
            return Object.assign({}, state, {giftCardInfo: newObject});
        case Actions.UPDATE_ORDER_IDENTIFIER:
            newObject = Object.assign({}, state.giftCardInfo, {orderIdentifier: action.value});
            return Object.assign({}, state, {giftCardInfo: newObject});
        case Actions.UPDATE_LINE_ITEM:
            let newLineItems = state.giftCardInfo.lineItems;

            for (let lineItem in state.giftCardInfo.lineItems) {
                if (state.giftCardInfo.lineItems[lineItem].lineItem.toLowerCase() === action.lineItem.lineItem.toLowerCase()) {
                    newLineItems[lineItem] = action.lineItem;
                }
            }
            let newGiftCardInfo = Object.assign({}, state.giftCardInfo, {lineItems: newLineItems});

            return Object.assign({}, state, {giftCardInfo: newGiftCardInfo});

        case Actions.SUBMIT_GIFT_NOW:
            return state;
        case Actions.SUBMIT_GIFT_NOW_REQUEST_START:
            state = Object.assign({}, state, {showLoadingOverlay: "giftNowRequest"});
            return state;
        case Actions.SUBMIT_GIFT_NOW_REQUEST_END:
            return state;
        case Actions.LOADING_COMPLETE:
            return Object.assign({}, state, {showLoadingOverlay: null});
        case Actions.SET_PAYMENT_COMPLETE:
            return Object.assign({}, state, {paymentComplete: action.value});

        default:
            return state;
    }
}
