import React, {Component} from 'react';
import { Router, Route} from 'react-router';
import {  routerMiddleware } from 'react-router-redux'
import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import thunk from "redux-thunk";
import {createBrowserHistory} from "history";
import {composeWithDevTools} from 'redux-devtools-extension';
import reducers from 'site/reducers';
import './variables.scss';
import './custom-bootstrap.scss';

import 'App.scss'

import { Banner, Navbar, Footer } from 'site/components'
import { Home } from 'Home/components'
import { GiftNow } from 'GiftNow/components'
import { RedeemNow } from 'RedeemNow/components'
import { FunIdeas } from 'FunIdeas/components'
import { AboutUs } from 'AboutUs/components'
import { FAQ } from 'FAQ/components'
import { Donate } from 'Donate/components'

import { config } from 'config.js';
import {Charities} from "./Charities/components";

const history = createBrowserHistory();
const middleware = routerMiddleware(history);
const store = createStore(reducers, composeWithDevTools(applyMiddleware(middleware, thunk)) );


class App extends Component {

    warningBanner() {
        let env = config.env;
        switch (env) {
            case "dev":
            case "demo":
            case "local":
                break;
            default:
                return null;
        }

        let text = "you are currently viewing the " + config.env + " site.";

        if (config.debug) {
            text = text + " debug mode";
        }
        return (
            <Banner text={text} leftMargin={"120px"} color={"red"}/>
        );
    };


    render() {
        return (
            <Provider store={store}>
                <div className="App" style={{height: "100%"}}>
                    <Router history={history}>
                        <div className={"h-100"}>

                            <Route path={"/"} component={Navbar} store={store} />

                            <Banner text={"giving the special children in your life the gift of giving"} leftMargin={"120px"} color={"yellow"}/>
                            {this.warningBanner()}
                            {/*<div className={"card col-xl-8 offset-xl-2 mt-xl-4 main-card-body"}>*/}
                            <div className={"row col-12 m-0 p-0 main-body-content"}>
                                {/*<div className={"card-body "}>*/}

                                    {/*routes*/}
                                    <Route exact path={"/"} component={Home}/>
                                    <Route exact path={"/gift-now"} component={GiftNow}/>
                                    <Route exact path={"/about-us"} component={AboutUs}/>
                                    <Route exact path={"/kidspage"} component={RedeemNow}/>
                                    <Route exact path={"/fun-ideas"} component={FunIdeas}/>
                                    <Route exact path={"/faq"} component={FAQ}/>
                                    <Route exact path={"/donate" } component={Donate}/>
                                    <Route exact path={"/charities" } component={Charities}/>


                                {/*</div>*/}
                            </div>
                            <Footer/>
                        </div>
                    </Router>
                </div>
            </Provider>
        );
    }

}

export default App;
