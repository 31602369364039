export default class GiftCardImageServices {

    constructor(props){
        this.props = props;
    }

    getGreetingById(greetingId){
        let greeting = Object.keys(this.props.greetings)
            .filter( (key) => ( this.props.greetings[key].id === greetingId ))
            .map( (key) => { return this.props.greetings[key] } );

        return greeting[0];
    }

    replaceGreetingText(text, recipientName, purchaserName){
        if ( text !== null && typeof text !== "undefined" ){
            if ( text.indexOf("{recipient_name}") !== -1 ){
                text = text.replace("{recipient_name}", recipientName);
            }
            if ( text.indexOf("{purchaser_name}") !== -1 ){
                text = text.replace("{purchaser_name}", purchaserName);
            }
        }

        return text
    }

}



