import React, { Component } from 'react';
import { connect} from "react-redux";
import { push } from 'react-router-redux';

import logo from 'images/Logo_Waves-clear-background.PNG';
import 'site/css/Navbar.css';
import 'App.scss';
import {SET_GIFT_NOW_STEP} from "../../GiftNow/actions/GiftNowActions";
import {SET_REDEEM_NOW_STEP} from "../../RedeemNow/actions/RedeemNowActions";

class Navbar extends Component {

    constructor(props, context) {
        super(props);
    };

    render() {

        let location  = window.location.pathname;

        return(
            <nav className="navbar navbar-expand-lg navbar-light navbar-container white-background ">
                <div className={"navbar-brand ml-auto navbar-logo-container"}  onClick={() => this.props.changePageToHome()}>
                    <img src={logo} className="logo" alt="logo" onClick={() => this.props.changePageToHome()} />
                </div>


                {/*<a className="navbar-brand" href="#">Kidsgiving</a>*/}
                <button className="navbar-toggler " type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon " />
                </button>

                <div className="collapse navbar-collapse white-background" id="navbarSupportedContent">
                    <ul className="nav navbar-main justify-content-end ">
                        <li className={"nav-item " + (location === "/" ? "active":"")}>
                            <span onClick={() => this.props.changePageToHome() } >home</span>
                        </li>
                        <li className={"nav-item " + (location === "/gift-now" ? "active":"")}>
                            <span onClick={() => this.props.changePageToGiftNow() } >gift now</span>
                        </li>
                        <li className={"nav-item " + (location === "/kidspage" ? "active":"")}>
                            <span onClick={() => this.props.changePageToKidsPage() } >kidspage</span>
                        </li>
                        <li className={"nav-item " + (location === "/faq" ? "active":"")}>
                            <span onClick={() => this.props.changePageToFAQ() } >faq</span>
                        </li>
                        <li className={"nav-item " + (location === "/donate" ? "active":"")}>
                            <span onClick={() => this.props.changePageToDonate() } >support kidsgiving</span>
                        </li>
                        <li className={"nav-item " + (location === "/about-us" ? "active":"")}>
                            <span onClick={() => this.props.changePageToAboutUs() } >about us</span>
                        </li>
                    </ul>
                </div>

            </nav>
        );
    }
}


const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changePageToHome : () => {dispatch(push("/"));},
        changePageToGiftNow : () => {
            dispatch({type: SET_GIFT_NOW_STEP, giftNowStep: (1) });
            dispatch(push("/gift-now" ));
        },
        changePageToRedeemNow : () => {
            dispatch({type: SET_REDEEM_NOW_STEP, redeemNowStep: (1) });
            dispatch( push("/redeem-now"));
        },
        changePageToAboutUs : () => dispatch(push("/about-us")),
        changePageToKidsPage : () => {
            dispatch({type: SET_REDEEM_NOW_STEP, redeemNowStep: (1) });
            dispatch(push("/kidspage"))
        },
        changePageToFunIdeas : () => dispatch(push("/fun-ideas")),
        changePageToFAQ : () => dispatch(push("/faq")),
        changePageToDonate: () => dispatch(push("/donate"))

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Navbar);


