import * as Actions from "../actions/FaqActions";

const initialState = {
    faqs: []
};

export default function faqReducer(state = initialState, action) {

    switch(action.type) {

        case Actions.FAQ_UPDATE_FAQS:
            return Object.assign({}, state, { faqs: action.faqs });

        default:
            return state;
    }
}
