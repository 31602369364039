import React, {Component} from 'react';
import {connect} from "react-redux";
import { withRouter } from "react-router";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6

import * as Actions from "../actions/RedeemNowActions";

import { PreviewBanner } from 'site/components'
import { CategoryDisplay } from 'RedeemNow/components';
import 'RedeemNow/css/RedeemNow.scss'

import { createBrowserHistory } from 'history';

class RedeemNowCategorySelection extends Component {

    redeemNowCategorySelectionListener= () => {console.log("error:: redeemNowCategorySelectionListener has not been set")};
    unListenCategorySelection = () => {console.log("error:: unListenCategorySelection has not been set")};
    componentDidMount() {
        window.scrollTo(0,0);

        const history = createBrowserHistory();

        this.redeemNowCategorySelectionListener = () => history.listen((location, action) => {
            if ( action === "POP"){
                this.unListenCategorySelection();
                this.props.previousRedeemNowStep(this.props.currentRedeemNowStep);
            }
        });

        this.unListenCategorySelection = history.listen(this.redeemNowCategorySelectionListener);

        window.onpopstate  = () => {
            history.go(1);
            window.onpopstate = () => {};
        };

        try{
            this.props.getRedemptionCategories();
            this.props.getRedemptionOrganizations();
        } catch(e) {
            console.log("Error loading redemptionCategories:");
            console.log(e);
        }
    }

    componentWillUnmount() {
        this.unListenCategorySelection();
    }

    handleCategoryClick = (categoryId) => {
        this.props.selectCategory(categoryId);
        this.unListenCategorySelection();
        this.props.advanceToNextRedeemNowStep(this.props.currentRedeemNowStep);
    };

    isPreviewButton = (isPreview, startPreview) => {

        if ( isPreview && startPreview){
            return (
                <div className={"row"}>
                    <div className={"col-12 mt-2 mb-2"}>
                        <button type="button clear mt-2"
                                className="btn btn-primary"
                                onClick={startPreview}>
                            click here to view current charity organizations
                        </button>
                    </div>
                </div>
            );
        }
    };

    showBackground = () => {
        if (!this.props.isPreview) {
            return (<img src={"/images/art-blur-close-up-6334.jpg" }
                         alt={"canvas with heart painted on it"}
                         style={{
                objectFit: 'cover',
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%'}} />);
        }
    };

    render() {

        let headerTextStyle = {};
        if ( this.props.greetingFontSize && this.props.greetingLineHeight){
            headerTextStyle = {
                "fontSize" : this.props.greetingFontSize + "px",
                "lineHeight" : this.props.greetingLineHeight + "px",
            }
        }

        let purchaserName = this.props.purchaserName;
        let amount = this.props.amount;
        if ( this.props.isPreview || this.props.redeemNow.isPreview ){
            if (this.props.giftCardInfo){
                purchaserName = this.props.giftCardInfo.purchaserName ? this.props.giftCardInfo.purchaserName : "Someone";
                amount = this.props.giftCardInfo.amount;
            } else {
                purchaserName = this.props.redeemNow.purchaserName ? this.props.redeemNow.purchaserName : "Someone";
                amount = this.props.redeemNow.amount ? this.props.redeemNow.amount : 10;
            }
        }

        return (
            <div className={"redeem-now-container"}>

                {this.showBackground()}

                <PreviewBanner isPreview={this.props.redeemNow.isPreview}/>
                <ReactCSSTransitionGroup
                        transitionName="redeem-now-transition-group"
                        transitionAppearTimeout={500}
                        transitionEnterTimeout={500}
                        transitionLeaveTimeout={300}
                        transitionAppear={true}>
                    <div className={"row m-5"}>
                        <div className={"col-12 offset-lg-3 col-lg-6 offset-md-2 col-md-8 card card-body"}>
                            <div className={"row justify-content-center"}>
                                <h1 className={"redeem-now-header"} style={headerTextStyle} >{this.props.text}</h1>
                            </div>
                            <div className={"row m-2"}>
                                <div className={"col-12"}>
                                    <span className={"redeem-now-text "}>{purchaserName} has gifted ${amount} for you to decide how to make a difference in the world.</span>
                                </div>
                            </div>

                            <div>
                                <div className={"row m-2"}>
                                    {this.props.redemptionCategories ? this.props.redemptionCategories.map((redemptionCategory)=>{
                                        return <CategoryDisplay
                                                key={redemptionCategory.id}
                                                sortOrder={redemptionCategory.sortOrder}
                                                categoryId={redemptionCategory.id}
                                                title={redemptionCategory.categoryName}
                                                text={redemptionCategory.descriptiveText}
                                                image={redemptionCategory.imageUrl}
                                                onClick={ this.handleCategoryClick }
                                                isPreview={this.props.isPreview}
                                        />
                                    }) : null }

                                </div>

                            </div>

                            {this.isPreviewButton(this.props.isPreview, this.props.startPreview)}

                        </div>
                    </div>
                </ReactCSSTransitionGroup>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        redeemNow : state.redeemNow,
        currentRedeemNowStep : state.redeemNow.redeemNowStep,
        categoryId : state.redeemNow.categoryId,
        redemptionCategories : state.redeemNow.redemptionCategories,
        redemptionOrganizations : state.redeemNow.redemptionOrganizations,
        recipientName: state.redeemNow.recipientName,
        purchaserName: state.redeemNow.purchaserName,
        amount: state.redeemNow.amount
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        selectCategory : (categoryId) => dispatch(Actions.setCategory(categoryId)),
        advanceToNextRedeemNowStep : (currentRedeemNowStep) => dispatch(Actions.advanceToNextRedeemNowStep(currentRedeemNowStep)),
        getRedemptionCategories: () => {dispatch(Actions.getRedemptionCategories())},
        getRedemptionOrganizations: () => {dispatch(Actions.getRedemptionOrganizations())},
        previousRedeemNowStep : (currentRedeemNowStep) => dispatch(Actions.previousRedeemNowStep(currentRedeemNowStep)),
    };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RedeemNowCategorySelection));

