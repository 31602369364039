import * as Actions from "../actions/DonateActions";

const dollarRegex = /^[1-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;
const initialState = {
    donationId:null,
    message: "",
    customAmountEnabled: false,
    donationAmount: 20.00
};

export default function DonateReducer(state = initialState, action) {

    switch(action.type) {

        case Actions.SUBMIT_DONATION_REQUEST_START:
            return state;
        case Actions.UPDATE_DONATION_ID:
            return Object.assign({}, state, { donationId: action.value });
        case Actions.UPDATE_DONATE_MESSAGE:
            return Object.assign({}, state, { message: action.value });
        case Actions.UPDATE_DONATE_AMOUNT:
            if ( dollarRegex.test(action.value)){
                return Object.assign({}, state, { donationAmount: Number(action.value) });
            } else {
                return state;
            }
        case Actions.TOGGLE_CUSTOM_AMOUNT_ENABLED:
            return Object.assign({}, state, { customAmountEnabled: !state.customAmountEnabled });
        case Actions.SUBMIT_DONATION_REQUEST_END:
            return state;
        case Actions.UPDATE_DONATION_PAYPAL_DATA_START:
            return state;
        case Actions.UPDATE_DONATION_PAYPAL_DATA_END:
            return state;
        default:
            return state;
    }
}