import React, {Component} from 'react';
import {connect} from "react-redux";
import { push } from 'react-router-redux'

import { Banner } from 'site/components'

import previewCard from 'images/kidsgiving_gift-card_L2-3.png';
import stepOne from 'images/step-one.png';
import stepTwo from 'images/step-two.png';

import {SET_GIFT_NOW_STEP} from "../../GiftNow/actions/GiftNowActions";
import {SET_REDEEM_NOW_STEP} from "../../RedeemNow/actions/RedeemNowActions";


import 'Home/css/Home.css'

class Home extends Component {

    constructor(props) {
        super(props);

        if ( this.props.location.hash === "#!gift-now" ){
            this.props.changePageToGiftNow();
        }
        if ( this.props.location.hash === "#!about-us" ){
            this.props.changePageToAboutUs();
        }
        if ( this.props.location.hash === "#!kidspage" ){
            this.props.changePageToKidsPage();
        }
        if ( this.props.location.hash === "#!faq" ){
            this.props.changePageToFAQ();
        }
        if ( this.props.location.hash === "#!donate" ){
            this.props.changePageToDonate();
        }
    }

    render() {

        return (
            <div className={"home-background-container"}>
                <div className={"row title-row"}>
                    <div className={"col-12"}>
                        <h3 className="title-text">searching for a really special gift for a child in your life?</h3>
                        <h2 className="title-text">kids care,  give what matters</h2>
                    </div>
                </div>
                <div className={"row p-3 align-items-center m-0"}>
                    <div className={"col-md-6 offset-xl-1 col-xl-5 "}>
                        <img src={previewCard} className={"card-image"} alt={"gift card preview"} />
                    </div>
                    <div className={"col-md-6 col-xl-5"}>
                        <div className={"row card card-block step-container step-card "}>
                            <div className="col-12 col-xl-auto justify-content-center align-self-center">
                                <img src={stepOne} className="step-image" alt="gift step one" />
                            </div>
                            <div className="col text-justify align-middle align-self-center">
                                <span className={"step-text align-middle"}>
                                    kidsgiving gifts are great for any occasion, and - if we can be honest- better than most of the 'stuff' children accumulate. with your tax-deductible gift amount, you create a card and we'll send it to a child so they can use their special code to help others
                                    <div className={"row"}>
                                        <div className={"col text-center"}>
                                            <button type="button"
                                                    className={"btn btn-primary gift-now-button text-align-center m-2"}
                                                    onClick={() => this.props.changePageToGiftNow()}>gift now</button>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>

                        <div className={"row card card-block step-container step-card "}>
                            <div className="col-12 col-xl-auto justify-content-center align-self-center">
                                <img src={stepTwo} className="step-image" alt={"gift step two"} />
                            </div>
                            <div className="col text-justify align-middle align-self-center">
                                <span className={"step-text text-justify"}>
                                    kids really do want to make a difference. when they redeem your card online, they choose how to give to those in need through selected charities. kidsgiving gives kids the power!
                                    <div className={"row"}>
                                        <div className={"col text-center"}>
                                            <button type="button"
                                                    className={"btn btn-primary gift-now-button text-align-center m-2"}
                                                    onClick={() => this.props.changePageToKidsPage()}>redeem a token</button>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row m-0 mt-4 p-0 justify-content-center"}>
                    <div className={"col-6 m-0 mt-4 p-0 justify-content-center"}>
                        <button type="button" className={"btn btn-primary gift-now-button"} onClick={() => this.props.changePageToGiftNow() } >gift now</button>
                    </div>
                </div>

                <Banner text={"why kidsgiving?"} color={"yellow"}/>

                <div className={"row m-0 pt-4 pb-4 white-background opacity"}>
                    <div className={"col-12 offset-xl-2 col-xl-8 text-justify "}>
                        <h5 className={""}>
                            you could give directly to a charity for a child but where's the fun in that? by making your
                            tax-deductible donation through kidsgiving, you're giving a child the power to choose how to
                            have an impact, the satisfaction of an action they take to help others. and it all comes with a
                            secret code to make it fun, a collectible token to make it memorable, and a card from you to
                            make it special. you can't buy that in a store.
                        </h5>
                    </div>
                </div>


                <Banner text={"support kidsgiving"} color={"yellow"}/>

                <div className={"row m-0 pt-4 white-background opacity"}>
                    <div className={"col-12 offset-xl-2 col-xl-8 text-justify "}>
                        <h5 className={" text-center"}>
                            interested in helping us make a difference?  you can support us directly as well!
                        </h5>
                        <h5 className={" text-center"}>
                            supporting the kidsgiving non-profit organization will help cover operating costs and
                            allow us to continue sharing the gift of giving with kids
                        </h5>
                    </div>
                </div>

                <div className={"row m-0 p-0 justify-content-center white-background opacity"}>
                    <div className={"col-6 m-0 mt-4 p-0 justify-content-center"}>
                        <button type="button" className={"btn btn-primary gift-now-button"} onClick={() => this.props.changePageToDonate() } >support kidsgiving</button>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return { };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changePageToHome : () => {dispatch(push("/"));},
        changePageToGiftNow : () => {
            dispatch({type: SET_GIFT_NOW_STEP, giftNowStep: (1) });
            dispatch(push("/gift-now" ));
        },
        changePageToAboutUs : () => dispatch(push("/about-us")),
        changePageToKidsPage : () => {
            dispatch({type: SET_REDEEM_NOW_STEP, redeemNowStep: (1) });
            dispatch(push("/kidspage"))
        },
        changePageToFAQ : () => dispatch(push("/faq")),
        changePageToDonate: () => dispatch(push("/donate"))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Home);


