import * as Actions from "../actions/RedeemNowActions";

import { config } from 'config.js';

const debugState = {
    redeemNowStep : 1,
    isPreview : false,
    categoryId : 3,
    redeemNowCode: "",
    captcha: null,
    recipientName: "Steelsen",
    purchaserName: "Lauren",
    amount: "20.00",
    redemptionCategories: [],
    redemption: {
        "id":30,
        "sessionId":"Lhj5ne53mMZPNM-K4d8kaVqcyZYbtewKQqEX6W0bGNYLatW3iAf9qIK_rR3MMfV9wWRpiGyO2AF",
        "expirationDate":"2018-11-01T01:40:53.7731027+00:00",
        "redemptionCode":"special_code",
        "captcha":"captcha",
        "order":{
            "packageId":null,
            "cardTypeId":null,
            "cardTemplateId":null,
            "orderStatus":null,
            "id":10,
            "orderIdentifier":"81382721-ab53-44d8-927e-d719dea6acbf",
            "purchaseAccount":null,
            "fromName":"test",
            "recipientName":"test",
            "customMessage":null,
            "shippingFirstName":"jtyf",
            "shippingLastName":"kuyfk",
            "shippingAddress1":"uyfuy",
            "shippingAddress2":"fkuyfk",
            "shippingCity":"yufkuyf",
            "shippingState":"kyufk",
            "shippingCountry":"United States",
            "shippingZip":"adsfhswyf",
            "package":null,
            "cardType":null,
            "cardTemplate":null,
            "redemptionOption":null,
            "lineItems":null,
            "orderStatuses":null,
            "payPalData":null
        },
        "selection":null
    }

};

const initialState = {
    redeemNowStep : 1,
    categoryId : 0,
    redeemNowCode: "",
    captcha: null,
    recipientName: "",
    purchaserName: "",
    amount: "",
    redemption: {}

};

export default function RedeemNowReducer(state = (config.debug ? debugState : initialState), action) {

    switch(action.type) {

        case Actions.UPDATE_PREVIEW_STATE:
            return Object.assign({}, state, {
                isPreview: action.isPreview,
                recipientName: action.recipientName,
                purchaserName: action.purchaserName,
                amount: action.amount});

        case Actions.SET_REDEEM_NOW_STEP:
            return Object.assign({}, state, { redeemNowStep: action.redeemNowStep });

        case Actions.SET_CATEGORY_ID:
            console.log("setting category id: " + action.categoryId);
            return Object.assign({}, state, { categoryId: action.categoryId });

        case Actions.REDEEM_NOW_UPDATE_REDEMPTION:
            console.log("updating redemption");

            console.log("action.redemption");
            console.log(action.redemption);

            return Object.assign({}, state, {
                redemption: action.redemption,
                recipientName: action.redemption.order.recipientName,
                purchaserName: action.redemption.order.fromName,
                amount: action.redemption.amount
            });

        /*redemptionCategories*/
        case Actions.GIFT_CARD_INFO_CHANGE_REDEMPTION_CATEGORIES:
            return Object.assign({}, state, { redemptionCategories: action.redemptionCategories } );

        /*redemptionOrganizations*/
        case Actions.GIFT_CARD_INFO_CHANGE_REDEMPTION_ORGANIZATIONS:
            return Object.assign({}, state, { redemptionOrganizations: action.redemptionOrganizations } );

        case Actions.UPDATE_REDEEM_NOW_CODE:
            return Object.assign({}, state, { redeemNowCode: action.value } );

        case Actions.UPDATE_REDEEM_NOW_CAPTCHA:
            return Object.assign({}, state, { captcha: action.value } );

        case Actions.SUBMITTED_REDEEM_NOW_CODE:
            console.log("Submitted Redeem Now Code:");
            return Object.assign({}, state);


        default:
            return state;
    }
}

