import React, { Component } from 'react';
import {connect} from "react-redux";
import 'site/css/Banner.css'

class Banner extends Component {
    render() {
        return(
            <div className={"row w-p100 header-banner-row m-0 header-banner-" + this.props.color}>
                <div className="col-12 text-center">
                    <span className={"header-banner-text align-middle"}>{this.props.text}</span>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};


export default connect(mapStateToProps, mapDispatchToProps)(Banner);


