import React, {Component} from 'react';
import {connect} from "react-redux";

class LoadingOverlay extends Component {

    overlayStyle = {
        backgroundColor: "rgba(0, 0, 0, 0.35)",
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        zIndex: "1000"
    };

    overlayText = {
        position: "absolute",
        top: "37%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    };

    spinnerStyle = {
        width: "8rem",
        height: "8rem"
    };

    render() {
        if (this.props.isLoading) {
            return (
                <div style={this.overlayStyle}>
                    <div style={this.overlayText}>
                        <div className={"spinner-border text-warning"} style={this.spinnerStyle}>
                            <div className={"sr-only"}>
                                loading...
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }

    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoadingOverlay);