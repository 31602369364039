import 'Donate/css/Donate.scss';

import React, {Component} from 'react';
import {connect} from "react-redux";
import {InputField, InputRadio, InputTextArea, PaypalButton, LoadingOverlay} from 'site/components';
import thankYouImage from 'images/thank-you-page-image.jpg';
import * as Actions from "../actions/DonateActions";
import {startDonation, submitDonationPaypalData} from "../actions/DonateActions";


class Donate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            authorizationError: false,
            isLoading: false,
            donated: false
        };
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    showAuthorizationError() {
        if (this.state.authorizationError) {
            return (
                <div className={"row m-0 m-5"}>
                    <div className={"col-12"}>
                        <div ref="authErrorButton" id="authErrorButtonba/n"
                             className="alert alert-danger alert-dismissible fade show" role="alert">
                            there was an error processing your request.<br/>
                            try again?
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>false
                    </div>
                </div>
            );
        }
    }

    thankYouBanner() {
        if (this.state.donated) {
            return (
                <div className={"row title-row"}>
                    <div className={"col-12 "}>
                        <span>thank you for your donation!</span>
                    </div>
                </div>
            );
        }
    }

    donationInput() {
        if (!this.state.donated) {
            return (
                <div className={"card-body"}>
                    <div className={"text-justify"}>
                        <p>interested in helping us make a difference? you can support us directly as well!</p>
                        <p>supporting the kidsgiving non-profit organization will help cover operating costs and allow
                            us to continue sharing the gift of giving with kids</p>


                        <div ref={"processingError"}>
                            {this.showAuthorizationError()}
                        </div>

                        <p>please leave us a note with your donation:</p>

                        <InputTextArea
                            textAreaClass={"donate-text-area"}
                            value={this.props.message}
                            onChange={(e) => this.updateDonateMessage(e.target.value)}
                            hideHelperText={true}/>

                        <div className={"mt-3"}>
                            <p>donation amount:</p>
                        </div>

                        <div className={"row mt-1"}>
                            <div className={"col-12 col-xl-10 offset-xl-1 col-lg-6 offset-lg-3"}>
                                {this.displayAmountInputs()}
                            </div>
                        </div>

                        <div className={"checkout-button m-auto"}>

                            {/*validation- paypal button*/}

                            <PaypalButton
                                amount={this.props.donationAmount}
                                kidsgivingPrepaymentRequest={(successCallback) => {
                                    this.setState({
                                        isLoading: true
                                    });
                                    this.props.startDonation(
                                        this.props.message,
                                        this.props.donationAmount,
                                        null,
                                        successCallback,
                                        () => {
                                            console.log("authorizationFailure");
                                            this.setState({
                                                authorizationError: true,
                                                isLoading: false
                                            });
                                            this.refs.processingError.scrollIntoView();
                                        }
                                    )
                                }}
                                afterAuthorizeCallback={(payPalData) => {
                                    this.props.submitDonationPaypalData(
                                        this.props.donationId,
                                        payPalData,
                                        () => {
                                            this.setState({
                                                donated: true,
                                                isLoading: false,
                                            })
                                        });
                                }}
                                afterErrorCallback={(error) => {
                                    console.log("error callback");
                                    console.log(error);
                                    this.setState({
                                        donated: false,
                                        isLoading: false,
                                    })
                                }}
                            />

                        </div>
                    </div>
                </div>

            );
        } else {
            return (
                <div className={"card-body"}>
                    <p>Thank you for your donation! We greatly appreciate your support.</p>
                    <p>You will receive a receipt at the email address associated with your PayPal account.</p>
                </div>
            );
        }
    }

    updateDonateMessage = (value) => {
        if (value && value.length <= 1000) {
            this.props.updateDonateMessage(value);
        } else if (!value) {
            this.props.updateDonateMessage("");
        }
    };

    displayAmountInputs = () => {
        if (!this.props.customAmountEnabled) {
            return (
                <div className={"row justify-content-center text-center"}>

                    <div className={"col-sm-3"}>
                        <InputRadio
                            label={"$20"}
                            value={20}
                            checkedAmount={this.props.donationAmount}
                            onChange={(e) => this.props.updateDonationAmount(e.target.value)}
                        />
                    </div>
                    <div className={"col-sm-3"}>
                        <InputRadio
                            label={"$40"}
                            value={40}
                            checkedAmount={this.props.donationAmount}
                            onChange={(e) => this.props.updateDonationAmount(e.target.value)}
                        />
                    </div>
                    <div className={"col-sm-3"}>
                        <InputRadio
                            label={"$50"}
                            value={50}
                            checkedAmount={this.props.donationAmount}
                            onChange={(e) => this.props.updateDonationAmount(e.target.value)}
                        />
                    </div>
                    {/*toggle other*/}
                    <div className={"col-sm-3"}>

                        <input type="checkbox" aria-label="custom amount"
                               className={"checkbox align-middle m-0 mt-1 mb-2 clickable"}
                               onChange={(e) => this.props.toggleCustomAmountEnabled()}
                               value={this.props.customAmountEnabled}
                               checked={this.props.customAmountEnabled}
                        />
                        <div className={"row clear-both display-block justify-content-center"}>
                            other
                        </div>

                    </div>

                </div>


            );
        } else {
            return (
                <div>

                    {/*Other*/}
                    <InputField label={"amount"} value={this.props.donationAmount}
                                onChange={(e) => this.props.updateDonationAmount(e.target.value)}/>
                </div>
            );
        }
    };

    render() {
        return (
            <div>
                <LoadingOverlay isLoading={this.state.isLoading} />
                <div className={"w-100"}>

                    {this.thankYouBanner()}

                    <div className={"row m-0 mt-2 mb-4"}>
                        <div className={"col-12 col-xl-4"}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <div className={"card m-3"}>
                                        <div className={"card-header"}>
                                            <div className={"text-center"}>
                                                <h4>thank you for your support!</h4>
                                            </div>
                                        </div>

                                        {this.donationInput()}

                                    </div>
                                </div>
                            </div>

                            <div className={"row "}>
                                <div className={"col-12"}>
                                    <div className={"card m-3 mt-xl-4"}>
                                        <div className={"card-body text-center"}>
                                            <p>kidsgivingorg.inc is a qualified tax-exempt 501(c)(3) organization</p>
                                            <p>all donations are tax-deductible to the extent allowed by law</p>
                                            <p>EIN: 84-2077621</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"col-12 col-xl-8 my-auto"}>
                            <div className={"card m-4"}>
                                <div className={"card-body"}>
                                    <img src={thankYouImage} className={"w-100"} alt={"token"}/>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        donationId: state.donate.donationId,
        message: state.donate.message,
        customAmountEnabled: state.donate.customAmountEnabled,
        donationAmount: state.donate.donationAmount
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        startDonation: (message, amount, payPalData, successCallback, failureCallback) => dispatch(startDonation(message, amount, payPalData, successCallback, failureCallback)),
        submitDonationPaypalData: (donationId, payPalData, successCallback) => dispatch(submitDonationPaypalData(donationId, payPalData, successCallback)),
        updateDonateMessage: (value) => dispatch({type: Actions.UPDATE_DONATE_MESSAGE, value: value}),
        updateDonationAmount: (value) => dispatch({type: Actions.UPDATE_DONATE_AMOUNT, value: value}),
        toggleCustomAmountEnabled: () => dispatch({type: Actions.TOGGLE_CUSTOM_AMOUNT_ENABLED}),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Donate);
