import React, { Component } from 'react';
import {connect} from "react-redux";

import 'GiftNow/css/GiftNow.scss'

class DropDownInputField extends Component {

    displayError = (error) => {
        if ( error && error !== ""){
            return (
                <div className="input-group below-input-container">
                    <div className={"offset-1 col-11 helper-text text-left"}>
                        <div className={"row"}>
                            <span className={"m-1 ml-4 p-0 input-error-message"}>{error}</span>
                        </div>
                    </div>
                </div>
            );
        }
    };

    displayLabel = (label) => {

        if ( label && label !== ""){
            return (
                <div className="input-group-prepend col-5 col-sm-4 pr-0 ">
                    <span className="input-group-text input-label-element justify-content-end" >{label}</span>
                </div>
            );
        }


    };

    displayHelperText = () => {
        if( !this.props.hideHelperText ){
            return (
                <div className="input-group below-input-container">
                    <div className={"offset-1 col-11 helper-text text-left"}>
                        <div className={"row m-1"}>
                            <span className={"m-1 ml-4 p-0"}>{this.props.helperText}</span>
                        </div>
                    </div>
                </div>
            );
        }
    };

    render() {

        if(this.props.options) {
            return(
                <div>
                    <div className="input-group ">

                        {this.displayLabel(this.props.label)}

                        <select className="form-control"
                                onChange={(e) => this.props.onChange(e)}
                                defaultValue={ this.props.defaultValue ? this.props.defaultValue : "" }>
                            <option value="" disabled>select {this.props.label}</option>
                            {Object.keys(this.props.options).filter(key => this.props.options[key].publicFacingName!=="Custom Message").map((key) =>{
                                    return (
                                        <option key={key}  value={this.props.options[key].id} >
                                            {( this.props.options[key].publicFacingName ?
                                                this.props.options[key].publicFacingName :
                                                ( this.props.options[key].displayName && this.props.options[key].displayName !== "" ?
                                                    this.props.options[key].displayName :
                                                    this.props.options[key].name? this.props.options[key].name : "" ) )}
                                        </option>
                                    );
                                }
                            )}
                        </select>
                    </div>
                    {this.displayHelperText()}

                    {this.displayError(this.props.error)}
                </div>
            )
        } else {
            return null;
        }
    }
}


const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};


export default connect(mapStateToProps, mapDispatchToProps)(DropDownInputField);


