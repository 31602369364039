import React, {Component} from 'react';
import {connect} from "react-redux";

import 'AboutUs/css/AboutUs.scss'

class AboutUs extends Component {

    componentDidMount() {
        window.scrollTo(0,0);
    }

    render() {
        return (
            <div className={"about-us-container"}>
                <img src={"/images/about-us-background.jpg" }
                     alt={"background- team supporting one another"}
                     style={{
                    objectFit: 'cover',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%'}} />

                <div className={"row title-row"}>
                    <div className={"col-12 "}>
                        <span>each order is hand packed and sent lovingly from New Haven, CT</span>
                    </div>
                </div>

                <div className={"row m-0 mt-2 mb-4"}>
                    <div className={"col-12 "}>

                        <div className={"row "}>
                            <div className={"col-md-6 col-xl-4 text-justify m-0 mb-5"}>
                                <div className={"card m-2"}>
                                    <div className={"card-header"}>
                                        <div className={"col-12 text-right"}>
                                            Lauren
                                        </div>
                                    </div>
                                    <div className={"card-body"}>
                                        <div className={"col-12 text-justify"}>
                                            <span className={"about-us-text"}>{this.props.aboutUs.bios.lauren()}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"col-md-6 col-xl-4 text-justify m-0 mb-5"}>
                                <div className={"card m-2"}>
                                    <div className={"card-header"}>
                                        <div className={"col-12 text-right"}>
                                            Steelsen
                                        </div>
                                    </div>
                                    <div className={"card-body"}>
                                        <div className={"col-12 text-justify"}>
                                            <span className={"about-us-text"}>{this.props.aboutUs.bios.steelsen()}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"col-md-6 col-xl-4 text-justify m-0 mb-5"}>
                                <div className={"card m-2"}>
                                    <div className={"card-header"}>
                                        <div className={"col-12 text-right"}>
                                            Nate
                                        </div>
                                    </div>
                                    <div className={"card-body"}>
                                        <div className={"col-12 text-justify"}>
                                            <span className={"about-us-text"} >{this.props.aboutUs.bios.nate()}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"col-md-6 col-xl-4 text-justify m-0 mb-5"}>
                                <div className={"card m-2"}>
                                    <div className={"card-header"}>
                                        <div className={"col-12 text-right"}>
                                            Jake
                                        </div>
                                    </div>
                                    <div className={"card-body"}>
                                        <div className={"col-12 text-justify"}>
                                            <span className={"about-us-text"} >{this.props.aboutUs.bios.jake()}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"col-md-6 col-xl-4 text-justify m-0 mb-5"}>
                                <div className={"card m-2"}>
                                    <div className={"card-header"}>
                                        <div className={"col-12 text-right"}>
                                            special thanks
                                        </div>
                                    </div>
                                    <div className={"card-body"}>
                                        <div className={"col-12 text-justify"}>
                                            <span className={"about-us-text"} >{this.props.aboutUs.bios.specialThanks()}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"col-md-6 col-xl-4 text-justify m-0 mb-5"}>
                                <div className={"card m-2"}>
                                    <div className={"card-header"}>
                                        <div className={"col-12 text-right"}>
                                            contact us
                                        </div>
                                    </div>
                                    <div className={"card-body"}>
                                        <div className={"col-12 text-justify"}>
                                            <p>kidsgivingorg.inc is a qualified tax-exempt 501(c)(3) organization.  EIN: 84-2077621</p>
                                            <p>other questions?</p>
                                            <p>reach out to us at <a href = "mailto: contact@kidsgiving.us">contact@kidsgiving.us</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
        )
    }
}



const mapStateToProps = (state) => {
    return {
        aboutUs : state.aboutUs
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);