import React, {Component} from 'react';
import {connect} from "react-redux";
import { withRouter } from "react-router";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6

import * as Actions from "../actions/RedeemNowActions";

import 'RedeemNow/css/RedeemNow.scss'
import { CharityCard } from './'
import { PreviewBanner } from 'site/components'

import {createBrowserHistory} from "history";

class RedeemNowCharitySelection extends Component {

    redeemNowCharitySelectionListener= () => {console.log("error:: redeemNowCategorySelectionListener has not been set")};
    unListenCharitySelection = () => {console.log("error:: unListenCategorySelection has not been set")};
    componentDidMount() {

        window.scrollTo(0,0);

        const history = createBrowserHistory();

        this.redeemNowCharitySelectionListener = () => history.listen((location, action) => {
            if ( action === "POP"){
                console.log("RedeemNowCharitySelection::: nav to previous redeem now step: " + 3);
                this.unListenCharitySelection();
                this.props.previousRedeemNowStep(3);
            }
        });

        this.unListenCharitySelection = history.listen(this.redeemNowCharitySelectionListener);

        window.onpopstate  = () => {
            history.go(1);
            window.onpopstate = () => {};
        };

        try{
            this.props.getRedemptionCategories();
            this.props.getRedemptionOrganizations();
        } catch(e) {
            console.log("Error loading getRedemptionOrganizations:");
            console.log(e);
        }
    }

    componentWillUnmount() {
        this.unListenCharitySelection();
    }

    tempCheckIfAnimal = function() {
        return (this.props.category.categoryName.toLowerCase().indexOf("animal") !== -1);
    };

    tempCheckIfEnvironment = function() {
        return (this.props.category.categoryName.toLowerCase().indexOf("environment") !== -1);
    };

    tempCheckIfChildren = function() {
        return (this.props.category.categoryName.toLowerCase().indexOf("children") !== -1);
    };

    tempCheckIfPeace = function() {
        return (
            this.props.category.categoryName.toLowerCase().indexOf("food") !== -1
            || this.props.category.categoryName.toLowerCase().indexOf("peace") !== -1
        );
    };

    cardDisplay = function(){

        let categoryCards = [];

        if ( this.props.redemptionOrganizations ){
            categoryCards = this.props.redemptionOrganizations.filter( (organization) => {
                return organization.organizationType.id === this.props.categoryId;
            });
        }

        if ( categoryCards.length === 0 ){
            return (
                <div className={"m-5"}>
                    sorry!  we're still working hard to find some charities that fit this category!
                </div>
            )
        }
        return (
            <div className={"row m-0 mb-5"}>
                { categoryCards.map(function(charity, i){
                    if (charity.active){
                        return (
                            <CharityCard
                                id = {charity.id}
                                key = {charity.id}
                                name = {charity.organizationName}
                                description = {charity.organizationDescription}
                                image = {charity.organizationImageUrl}
                                url = {charity.url}
                                urlText = {charity.urlText}
                                ein = {charity.ein}


                            />
                        );
                    } else {
                        console.log("not display " + charity.organizationName + " because it is not marked as active");
                        return null;
                    }

                }

                )}
            </div>
        );
    };


    render() {

        if ( !this.props.category ){
            return null;
        } else {
            let backgroundImage = {};

            if ( this.tempCheckIfChildren() ) {
                backgroundImage = "/images/category-help-children-2.jpg";
            } else if ( this.tempCheckIfAnimal() ){
                backgroundImage = "/images/protect-animals-placeholder-2.jpg";
            } else if ( this.tempCheckIfEnvironment() ){
                backgroundImage = "/images/environment-background-shutterstock.jpg";
            } else if ( this.tempCheckIfPeace() ){
                backgroundImage = "/images/sunsetpeacesigns.jpg";
            }

            return (
                <div className={"w-100 redeem-now"} >

                    <PreviewBanner isPreview={this.props.redeemNow.isPreview}/>
                    <ReactCSSTransitionGroup
                            transitionName="redeem-now-transition-group"
                            transitionAppearTimeout={500}
                            transitionEnterTimeout={500}
                            transitionLeaveTimeout={300}
                            transitionAppear={true}>

                        <div className={"container-fluid d-flex"}>
                            <div className={"row title-row d-flex w-100"}>
                                <div className={"col-sm-6 justify-content-center"}>
                                    <h1 className={"redeem-now-header"}>{this.props.category.categoryName}</h1>
                                </div>
                                <div className={"col-sm-6 justify-content-center align-self-center"}>
                                    <button type="button" className={"btn btn-secondary pl-5 pr-5"} onClick={() => {
                                        this.unListenCharitySelection();
                                        this.props.previousRedeemNowStep(3);
                                    } } >Back to Category Selection</button>
                                </div>
                            </div>
                        </div>

                        <div className={"charity-selection-container"}>
                            <div className={"row m-0"}>
                                <div className="col-12 mt-4">
                                    {/*todo: dynamic background description*/}
                                    <img src={backgroundImage}
                                         alt={"background for category"}
                                         style={{
                                        objectFit: 'cover',
                                        position: 'absolute',
                                        top: '0',
                                        left: '0',
                                        width: '100%',
                                        height: '100%'}} />
                                    {/*{this.tempCheckIfAnimal() || this.tempCheckIfPeace() ? this.cardDisplay() : this.tempCardDisplay()}*/}
                                    { this.cardDisplay() }
                                    {/*{JSON.stringify(this.props.redemption)}*/}
                                </div>
                            </div>
                        </div>
                    </ReactCSSTransitionGroup>
                </div>
            )
        }

    }
}


const mapStateToProps = (state) => {
    return {
        categoryId: state.redeemNow.categoryId,
        redemption: state.redeemNow.redemption,
        currentRedeemNowStep : state.redeemNow.redeemNowStep,
        redemptionCategories : state.redeemNow.redemptionCategories,
        redemptionOrganizations : state.redeemNow.redemptionOrganizations,
        category: state.redeemNow.redemptionCategories.find(function(element){return element.id === state.redeemNow.categoryId}),
        redeemNow: state.redeemNow,
        recipientName: state.redeemNow.recipientName,
        purchaserName: state.redeemNow.purchaserName
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        advanceToNextRedeemNowStep : (currentRedeemNowStep) => dispatch(Actions.advanceToNextRedeemNowStep(currentRedeemNowStep)),
        previousRedeemNowStep : (currentRedeemNowStep) => dispatch(Actions.previousRedeemNowStep(currentRedeemNowStep)),
        getRedemptionCategories: () => {dispatch(Actions.getRedemptionCategories())},
        getRedemptionOrganizations: () => {dispatch(Actions.getRedemptionOrganizations())}
    };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RedeemNowCharitySelection ));

