import React, {Component} from 'react';
import {connect} from "react-redux";

import {advanceToNextGiftNowStep, previousGiftNowStep, submitGiftNow, updatePaypalDataByOrder} from "../actions/GiftNowActions";
import * as Actions from "../actions/GiftNowActions";

import {InputField, DropDownInputField, PaypalButton, Error, LoadingOverlay} from 'site/components';
import {GiftCardImagePreview, GiftCardImageServices} from 'GiftNow/components/GiftCardImage';
import $ from 'jquery';
import { config } from 'config.js';

import 'GiftNow/css/GiftNow.scss'

import { FormattedNumber } from 'react-intl';

import { createBrowserHistory } from 'history';

class GiftCardShippingForm extends Component {

    constructor(props){
        super(props);
        this.getShippingCost = this.getShippingCost.bind(this);
        this.getTotalCost = this.getTotalCost.bind(this);
        this.getShippingMethodSecondaryText = this.getShippingMethodSecondaryText.bind(this);
        this.state = {
            authorizationError: false,
        };
    };

    unListenGiftCardShippingForm = () => {console.log("error:: unListenGiftCardShippingForm has not been set")};

    componentDidMount() {
        window.scrollTo(0,0);

        this.props.getShippingMethods();

        const history = createBrowserHistory();

        const giftCardShippingFormListener = history.listen((location, action) => {
            if ( action === "POP"){
                this.props.previousGiftNowStep(this.props.currentGiftNowStep);
            }
            this.unListenGiftCardShippingForm();
        });

        this.unListenGiftCardShippingForm = history.listen(giftCardShippingFormListener);

        window.onpopstate  = () => {
            history.go(1);
            window.onpopstate = () => {};
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ( this.refs.authErrorButton ){

            $("#authErrorButton").on('closed.bs.alert', function(setAuthorizeFalse){
                setAuthorizeFalse();
            }, this.setAuthorizeFalse);
        }
    }

    componentWillUnmount() {
        this.unListenGiftCardShippingForm();
    }

    setAuthorizeFalse = () => (this.setState({authorizationError: false}));


    getShippingCost(shippingMethodId){
        return Object.keys(this.props.shippingMethods)
            .filter((key) => (this.props.shippingMethods[key].id === shippingMethodId ))
            .map( (key) => { return parseFloat(this.props.shippingMethods[key].cost)} )
    };

    getTotalCost( giftCardAmount, shippingCost){
        return parseFloat(giftCardAmount) + parseFloat(shippingCost) +
            (this.props.giftCardInfo.donationEnabled ? parseFloat(this.props.giftCardInfo.donationAmount): "") ;
    };

    getShippingMethodSecondaryText(shippingMethodId){
        if( shippingMethodId && !isNaN(shippingMethodId) ){
            return Object.keys(this.props.shippingMethods)
                .filter((key) => (this.props.shippingMethods[key].id === shippingMethodId ))
                .map( (key) => { return this.props.shippingMethods[key].description} )
        }
    }

    displayGiftCard(){
        let imageServices = new GiftCardImageServices(this.props);
        if ( !this.props.giftCardInfo.customGreetingEnabled ){
            let greeting = imageServices.getGreetingById(this.props.giftCardInfo.greetingId);
            return (
                <GiftCardImagePreview
                    headerText = {imageServices.replaceGreetingText(greeting.header, this.props.giftCardInfo.recipientName, this.props.giftCardInfo.purchaserName)}
                    greetingFontSize = {32}
                    greetingLineHeight= {44}
                    line1 = {imageServices.replaceGreetingText(greeting.line1, this.props.giftCardInfo.recipientName, this.props.giftCardInfo.purchaserName)}
                    line2 = {imageServices.replaceGreetingText(greeting.line2, this.props.giftCardInfo.recipientName, this.props.giftCardInfo.purchaserName)}
                    line3 = {imageServices.replaceGreetingText(greeting.line3, this.props.giftCardInfo.recipientName, this.props.giftCardInfo.purchaserName)}
                    small = {false}
                />
            );
        } else {
            let greeting = this.props.customGreeting;
            let greetingLine1 = "{purchaser_name} has given you the gift of giving!";
            let purchaserName = "Someone";
            if ( this.props.giftCardInfo.purchaserName ){
                purchaserName = this.props.giftCardInfo.purchaserName;
            }

            return (
                <GiftCardImagePreview
                    headerText = {this.props.giftCardInfo.customGreetingText}
                    greetingFontSize = {this.props.giftCardInfo.greetingFontSize}
                    greetingLineHeight= {this.props.giftCardInfo.greetingLineHeight}
                    line1 = {imageServices.replaceGreetingText(greetingLine1, null, purchaserName)}
                    line2 = {greeting.line2}
                    line3 = {greeting.line3}
                    small = {false}
                />
            );
        }
    }

    displayDonation(){
        if ( this.props.giftCardInfo.donationEnabled ){
            return (
                <div className={"row mt-2"} >
                    <div className="col-6 text-left">
                        donation
                    </div>
                    <div className="col-6 text-right">
                        <FormattedNumber value={this.props.giftCardInfo.donationAmount} style="currency" currency="USD" />{// eslint-disable-line
                    }
                    </div>
                </div>
            );
        }
    }

    showErrorMessages = () => {

        let errors = this.getErrorMessages();

        if ( Object.keys(errors).length > 0 && errors.constructor === Object ){
            console.log("Errors");
            console.log(errors);
            this.props.updateGiftCardErrors(errors);
        }
        this.refs.shipToInfo.scrollIntoView();
    };

    getErrorMessages(){
        let errors = {};

        if ( !this.props.shipToInfo.firstName || this.props.shipToInfo.firstName === ""){
            errors.firstName = "Please include the shipping first name";
        }

        if ( !this.props.shipToInfo.lastName || this.props.shipToInfo.lastName === ""){
            errors.lastName = "Please include the shipping last name";
        }

        if ( !this.props.shipToInfo.address1 || this.props.shipToInfo.address1 === ""){
            errors.address1 = "Please include a shipping address";
        }

        if ( !this.props.shipToInfo.city || this.props.shipToInfo.city === ""){
            errors.city = "Please include the shipping city";
        }

        const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

        if ( !this.props.shipToInfo.state || this.props.shipToInfo.state === ""){
            errors.state = "Please include the shipping state";
        }

        if ( !this.props.shipToInfo.zipcode || this.props.shipToInfo.zipcode === ""){
            errors.zipcode = "Please include the shipping zipcode";
        } else if ( this.props.shipToInfo.zipcode
            && this.props.shipToInfo.zipcode !== ""
            && !isValidZip.test(this.props.shipToInfo.zipcode) ){
            errors.zipcode = "Please include a valid United States zip code.  This should be in a form such as: 12345 or 12345-1234";
        }

        if ( !this.props.shipToInfo.country || this.props.shipToInfo.country === ""){
            errors.country = "Please include the shipping country";
        }

        if ( !this.props.shippingInfo.shippingMethodId || this.props.shippingInfo.shippingMethodId === ""){
            errors.shippingMethod  = "Please select a shipping method";
        }
        return errors;
    }

    showErrorMessagesButton(){
        return (
            <button type="button" className={"btn btn-primary pl-5 pr-5 checkout-button"} onClick={() => this.showErrorMessages() } >checkout</button>
        );
    };

    showAuthorizationError(){

        if ( this.state.authorizationError ){
            return (
                <div className={"row m-0 m-5"}>
                    <div className={"offset-1 col-10 offset-lg-4 col-lg-4 "}>
                        <div ref="authErrorButton" id="authErrorButton" className="alert alert-danger alert-dismissible fade show" role="alert">
                            there was an error processing your request.  try again?
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>
            );
        }

    }

    debugData(){
        if ( config.debug === true ){
            return (
                <div className={"row mt-5"}>
                    <div className={"col-12 "}>
                        <div>
                            {JSON.stringify(this.props)}<br />
                        </div>
                    </div>
                </div>
            );
        }
    };

    debugPayment(){

        if ( window.location.href.indexOf("localhost") || !window.location.href.indexOf("dev.kidsgiving") ){
            return(
                <div className="fake-button" onClick={() => {
                    console.log("clicked fake payment");
                    this.props.setPaymentComplete(true);
                    this.props.advanceToNextGiftNowStep(this.props.currentGiftNowStep);}
                } >
                    just checking out the site,<br/>
                    let's skip the payment for now
                </div>
            );
        }

    }

    render() {

        if (this.props.hasError) {
            console.log("error");
            return <Error/>;

        } else {

            return (
                <div>
                    <LoadingOverlay isLoading={this.props.isLoading}/>
                    <div className={"w-100"}>
                        <div className={"row title-row"}>
                            <div className="row m-0">
                                <div className={"col-12"}>
                                    <h4>nice!</h4>
                                </div>
                            </div>
                            <div className="row clear-both m-0">
                                <div className={"col-12"}>
                                    <span>we'll let you know when your bright and beautiful card is mailed and when the gift is redeemed!</span>
                                </div>
                            </div>
                        </div>

                        {/***************SHIP TO****************/}
                        <div className={"row m-0 mt-5"}>
                            <div className={"col-lg-6 offset-xl-1 col-xl-5"}>
                                <div className={"row col-12 justify-content-center"}>
                                    <h5 ref={"shipToInfo"}>ship to</h5>
                                </div>

                                <InputField label={"first name"} value={this.props.shipToInfo.firstName}
                                            onChange={(e) => this.props.giftCardShipToChangeFirstName(e.target.value)}
                                            helperText={""}
                                            autoComplete={"first-name"}
                                            error={this.props.errors.firstName}/>
                                <InputField label={"last name"} value={this.props.shipToInfo.lastName}
                                            onChange={(e) => this.props.giftCardShipToChangeLastName(e.target.value)}
                                            helperText={""}
                                            autoComplete={"family-name"}
                                            error={this.props.errors.lastName}/>
                                <InputField label={"address"} value={this.props.shipToInfo.address1}
                                            onChange={(e) => this.props.giftCardShipToChangeAddress1(e.target.value)}
                                            helperText={""}
                                            autoComplete={"address-line1"}
                                            error={this.props.errors.address1}/>
                                <InputField label={"address line 2"} value={this.props.shipToInfo.address2}
                                            onChange={(e) => this.props.giftCardShipToChangeAddress2(e.target.value)}
                                            helperText={""}
                                            autoComplete={"address-line2"}
                                            error={this.props.errors.address2}/>
                                <InputField label={"city"} value={this.props.shipToInfo.city}
                                            onChange={(e) => this.props.giftCardShipToChangeCity(e.target.value)}
                                            helperText={""}
                                            autoComplete={"address-level2"}
                                            error={this.props.errors.city}/>
                                <InputField label={"state"} value={this.props.shipToInfo.state}
                                            onChange={(e) => this.props.giftCardShipToChangeState(e.target.value)}
                                            helperText={""}
                                            autoComplete={"address-level1"}
                                            error={this.props.errors.state}/>
                                <InputField label={"zipcode"} value={this.props.shipToInfo.zipcode}
                                            onChange={(e) => this.props.giftCardShipToChangeZipcode(e.target.value)}
                                            helperText={""}
                                            autoComplete={"postal-code"}
                                            error={this.props.errors.zipcode}/>
                                <InputField label={"country"} value={this.props.shipToInfo.country}
                                            onChange={(e) => this.props.giftCardShipToChangeCountry(e.target.value)}
                                            helperText={""}
                                            autoComplete={"country"}
                                            error={this.props.errors.country}
                                            disabled={true}/>

                                <div className={"mb-4"}>
                                    <DropDownInputField
                                        label={"shipping method"}
                                        onChange={(e) => this.props.shippingInfoChangeShippingMethodId(e.target.value)}
                                        options={this.props.shippingMethods}
                                        helperText={this.getShippingMethodSecondaryText(this.props.shippingInfo.shippingMethodId)}
                                        error={this.props.errors.shippingMethod}
                                    />
                                </div>

                            </div>

                            <div className={"offset-1 col-9 offset-lg-1 col-lg-4 "}>

                                {this.displayGiftCard()}

                                <div className={"m-4"}>
                                    <button type="button" className={"btn btn-primary pl-5 pr-5"}
                                            onClick={() => this.props.previousGiftNowStep(this.props.currentGiftNowStep)}>back
                                        to build your card
                                    </button>
                                </div>


                            </div>
                        </div>

                        <div ref={"processingError"}>
                            {this.showAuthorizationError()}
                        </div>

                        <div className={"row m-0 m-5"}>

                            <div className={"col-12 offset-lg-3 col-lg-6 offset-md-1 col-md-10"}>

                                <div className={"gray-card-background "}>
                                    <div className={"row "}>
                                        <div className="col-6 offset-3 justify-content-center">
                                            <h3>order summary</h3>
                                        </div>
                                        <div className="col-3">
                                            <div className="text-right my-auto">
                                                <button type="button" className={"btn btn-primary"}
                                                        onClick={() => this.props.previousGiftNowStep(this.props.currentGiftNowStep)}>back
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    {/*Email*/}
                                    <div className={"row mt-2"}>
                                        <div className="col-12mt-4 mb-4 text-left ">
                                            <h6>updates will be sent to</h6>
                                            <h4 className="break-word">{this.props.giftCardInfo.email}</h4>
                                        </div>

                                    </div>

                                    <hr/>

                                    {/*Gift Amount*/}
                                    <div className={"row mt-2"}>
                                        <div className="col-6 text-left">
                                            gift amount
                                        </div>
                                        <div className="col-6 text-right">
                                            <FormattedNumber value={this.props.giftCardInfo.amount}
                                                             style="currency" // eslint-disable-line
                                                             currency="USD"/> {
                                        }
                                        </div>
                                    </div>

                                    {/*Donation*/}
                                    {this.displayDonation()}

                                    {/*shipping*/}
                                    <div className={"row mt-2"}>
                                        <div className="col-6 text-left">
                                            shipping
                                        </div>
                                        <div className="col-6 text-right">
                                            {
                                                this.props.shippingInfo.shippingMethodId ?
                                                    (<FormattedNumber
                                                        value={this.getShippingCost(this.props.shippingInfo.shippingMethodId)}
                                                        style="currency" currency="USD"/>) : ""   // eslint-disable-line
                                            }

                                        </div>
                                    </div>

                                    <hr/>

                                    {/*Total*/}
                                    <div className={"row mt-2"}>
                                        <div className="col-6 text-left">
                                            total
                                        </div>
                                        <div className="col-6 text-right">
                                            {
                                                this.props.shippingInfo.shippingMethodId ?
                                                    (<FormattedNumber
                                                        value={this.getTotalCost(this.props.giftCardInfo.amount, this.getShippingCost(this.props.shippingInfo.shippingMethodId))}
                                                        style="currency" currency="USD"/>) :   // eslint-disable-line
                                                    ""
                                            }

                                        </div>
                                    </div>

                                    {/*paypal button*/}

                                    {/*adding an onclick to the payment button for now*/}
                                    <div className={"row"}>
                                        <div className={"col-12 mt-4 mb-4 text-center checkout-button"}>
                                            <div className={"checkout-button m-auto"}>

                                                {/*validation- paypal button*/}

                                                {Object.entries(this.getErrorMessages()).length === 0 && this.getErrorMessages().constructor === Object ?
                                                    (<PaypalButton
                                                        amount={this.getTotalCost(this.props.giftCardInfo.amount, this.getShippingCost(this.props.shippingInfo.shippingMethodId))}
                                                        kidsgivingPrepaymentRequest={(successCallback) => {
                                                            this.props.submitGiftNowInformation(
                                                                this.props.giftCardInfo,
                                                                this.props.greetings,
                                                                this.props.shipToInfo,
                                                                this.props.shippingInfo,
                                                                this.props.shippingMethods,
                                                                "prePaymentAuthorization",
                                                                null,
                                                                successCallback,
                                                                () => {
                                                                    console.log("authorizationFailure");
                                                                    this.setState({authorizationError: true});
                                                                    this.refs.processingError.scrollIntoView();
                                                                }
                                                            )
                                                        }}
                                                        afterAuthorizeCallback={(payPalData) => {
                                                            this.props.submitPaypalData(
                                                                this.props.giftCardInfo.orderId,
                                                                payPalData,
                                                                () => {
                                                                    this.props.setPaymentComplete(true);
                                                                    this.props.advanceToNextGiftNowStep(this.props.currentGiftNowStep);
                                                                    this.props.loadingComplete();
                                                                }
                                                            )
                                                        }}
                                                        afterErrorCallback={(error) => {
                                                            this.setState({authorizationError: true});
                                                        }}
                                                    />) : this.showErrorMessagesButton()
                                                }

                                            </div>
                                        </div>
                                    </div>

                                </div>

                                {/*Donation*/}
                                {this.debugPayment()}

                            </div>
                        </div>

                    </div>
                </div>
            )
        }
    }
}


const mapStateToProps = (state) => {

    return {
        currentGiftNowStep : state.giftNow.giftNowStep,
        shippingInfo : state.giftNow.shippingInfo,
        giftCardInfo : state.giftNow.giftCardInfo,
        greetings: state.giftNow.greetings,
        customGreeting: state.giftNow.customGreeting,
        shippingMethods: state.giftNow.shippingMethods,
        shipToInfo: state.giftNow.shipToInfo,
        errors: state.giftNow.errors,
        isLoading: state.giftNow.isLoading,
        hasError: state.giftNow.hasError
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getShippingMethods: () => {dispatch (Actions.getShippingMethods())},
        loadingComplete: () => {dispatch({type: Actions.SHIPPING_INFO_CHANGE_SHIPPING_METHOD_ID})},

        advanceToNextGiftNowStep : (currentGiftNowStep) => dispatch(advanceToNextGiftNowStep(currentGiftNowStep)),
        previousGiftNowStep : (currentGiftNowStep) => dispatch(previousGiftNowStep(currentGiftNowStep)),
        shippingInfoChangeShippingMethodId : (value) => dispatch ({type: Actions.SHIPPING_INFO_CHANGE_SHIPPING_METHOD_ID, value : value}),
        setPaymentComplete : (value) => dispatch ({type: Actions.SET_PAYMENT_COMPLETE, value : value}),

        submitGiftNowInformation : (giftCardInfo, greeting, shipToInfo, shippingInfo, shippingMethod, status, payPalData, successCallback, failureCallback) => dispatch(submitGiftNow(giftCardInfo, greeting, shipToInfo, shippingInfo, shippingMethod, status, payPalData, successCallback, failureCallback)),
        submitPaypalData : (orderId, payPalData, successCallback) => dispatch(updatePaypalDataByOrder(orderId, payPalData, successCallback)),

        giftCardInfoChangeGreetingId : (value) => dispatch ({type: Actions.GIFT_CARD_INFO_CHANGE_GREETING_ID, value : value}),
        giftCardInfoChangePurchaserName : (value) => dispatch ({type: Actions.GIFT_CARD_INFO_CHANGE_PURCHASER_NAME, value : value}),
        giftCardInfoChangeRecipientName : (value) => dispatch ({type: Actions.GIFT_CARD_INFO_CHANGE_RECIPIENT_NAME, value : value}),

        giftCardShipToChangeFirstName : (value) => dispatch ({type: Actions.SHIP_TO_INFO_CHANGE_FIRST_NAME, value : value}),
        giftCardShipToChangeLastName : (value) => dispatch ({type: Actions.SHIP_TO_INFO_CHANGE_LAST_NAME, value : value}),
        giftCardShipToChangeAddress1 : (value) => dispatch ({type: Actions.SHIP_TO_INFO_CHANGE_ADDRESS1, value : value}),
        giftCardShipToChangeAddress2 : (value) => dispatch ({type: Actions.SHIP_TO_INFO_CHANGE_ADDRESS2, value : value}),
        giftCardShipToChangeCity : (value) => dispatch ({type: Actions.SHIP_TO_INFO_CHANGE_CITY, value : value}),
        giftCardShipToChangeState : (value) => dispatch ({type: Actions.SHIP_TO_INFO_CHANGE_STATE, value : value}),
        giftCardShipToChangeZipcode : (value) => dispatch ({type: Actions.SHIP_TO_INFO_CHANGE_ZIPCODE, value : value}),
        giftCardShipToChangeCountry : (value) => dispatch ({type: Actions.SHIP_TO_INFO_CHANGE_COUNTRY, value : value}),
        updateGiftCardErrors : (errors) => { dispatch({type: Actions.UPDATE_GIFT_CARD_ERRORS, value: errors}) }
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(GiftCardShippingForm);

