import React, { Component } from 'react';
import {connect} from "react-redux";
import 'site/css/Banner.css'

class Banner extends Component {
    render() {
        return(
            <div className={"row w-100 m-0 p-5"}>
                <div className="col-12 text-center">
                    <p className={"align-middle"}>loading...</p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};


export default connect(mapStateToProps, mapDispatchToProps)(Banner);


