import React, {Component} from 'react';
import {connect} from "react-redux";
import { config } from 'config.js';
import {MiniCharityCard} from "../../RedeemNow/components";
import { Error,Loading } from 'site/components'

class Charities extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeOrgs: [],
            inactiveOrgs: [],
            dataIsLoaded: false,
            hasError: false
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        fetch(config.apiUrl + config.urls.redemptionOrganizations)
            .then((res) => res.json())
            .then((redemptionOrgs) => {


                console.log("got redemptionOrganizations");

                let activeOrgs = [];
                let inactiveOrgs = [];

                for (const redemptionOrg of redemptionOrgs) {

                    if (redemptionOrg.active){
                        activeOrgs.push(redemptionOrg);
                    } else {
                        inactiveOrgs.push(redemptionOrg);
                    }
                }

                this.setState({
                    activeOrgs: activeOrgs,
                    inactiveOrgs: inactiveOrgs,
                    dataIsLoaded: true
                });

            })
            .catch((error) => {
                console.log("error response");
                console.error(error);

                this.setState({
                    hasError: true
                });

            });

    }

    render() {
        const {activeOrgs, inactiveOrgs, dataIsLoaded, hasError} = this.state;

        if (!dataIsLoaded && !hasError) {
            return <Loading/>;
        } else if (hasError) {
            return <Error/>;
        } else {

            return (<div>

                <div className={"row m-0"}>
                    <div className={"col-12 text-left m-0 mt-5 pl-5"}>
                        <p>active charities</p>
                    </div>
                </div>

                <div className={"row m-0 m-3 "}>
                    {activeOrgs.map(function (charity) {
                        return (
                            <MiniCharityCard
                                id={charity.id}
                                key={charity.id}
                                name={charity.organizationName}
                                description={charity.organizationDescription}
                                image={charity.organizationImageUrl}
                                url={charity.url}
                                urlText={charity.urlText}
                                ein={charity.ein}
                                active={charity.active}
                            />
                        );
                    })}
                </div>


                <div className={"row m-0"}>
                    <div className={"col-12 text-left m-0 mt-1 mb-5 pl-5"}>
                        <p>inactive charities</p>

                        {inactiveOrgs.map(function (charity) {
                            return (
                                <p key={charity.id}>
                                    {charity.organizationName}
                                </p>
                            );
                        })}

                    </div>
                </div>

            </div>);

        }

    }
}



const mapStateToProps = (state) => {
    return {
        aboutUs : state.aboutUs
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};


export default connect(mapStateToProps, mapDispatchToProps)(Charities);