import React, {Component} from 'react';
import {connect} from "react-redux";

import {
    GiftCardInformationForm,
    GiftCardShippingForm,
    GiftCardThankYou
} from './';

import 'GiftNow/css/GiftNow.scss';

class GiftNow extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        switch (this.props.currentGiftNowStep) {
            case 1:
                return (
                    <div className={"gift-now-background-container"}>
                        <GiftCardInformationForm/>
                    </div>
                );
            case 2:
                return (
                    <div className={"gift-now-background-container"}>
                        <GiftCardShippingForm/>
                    </div>
                );
            case 3:
                return (<GiftCardThankYou/>);

            default:
                return <GiftCardInformationForm/>
        }
    }
}


const mapStateToProps = (state) => {
    return {
        currentGiftNowStep: state.giftNow.giftNowStep,
        loading: state.giftNow.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};


export default connect(mapStateToProps, mapDispatchToProps)(GiftNow);

