import React, { Component } from 'react';
import {connect} from "react-redux";

/*import 'GiftNow/css/GiftNow.scss'*/
import 'site/css/InputField.css'

class InputField extends Component {

    displayError = (error) => {
        if ( error && error !== ""){
            return (
                <div className="input-group below-input-container">
                    <div className={"offset-1 col-11 helper-text text-left"}>
                        <div className={"row"}>
                            <span className={"m-1 ml-4 p-0 input-error-message"}>{error}</span>
                        </div>
                    </div>
                </div>
            );
        }
    };

    displayHelperText = () => {
        if( !this.props.hideHelperText ){
            return (
                <div className="input-group below-input-container">
                    <div className={"offset-1 col-11 helper-text text-left"}>
                        <div className={"row m-1"}>
                            {this.props.helperText}
                        </div>
                    </div>
                </div>
            );
        }
    };

    displayInfoPopup = (text) => {
        if ( text && text !== ""){
            return (
                <div title={this.props.helperText} className={"info-popup"}>
                    <i className="fas fa-info-circle" ></i>
                </div>
            );
        } else { return (
            <div className={"info-popup"}></div>
        );}
    };

    checked = () => {
        if ( this.props.overrideCheckedAmount ){
            return true;
        } else {
            return (parseInt(this.props.value) === parseInt(this.props.checkedAmount)) ;
        }

    };


    render() {
        return(
                <div>
                    <div className="input-group radio-input-container justify-content-center">
                        <input
                            type="radio"
                            className="form-control display-block radio-input justify-content-center"
                            value={this.props.value}
                            aria-label={this.props.value}
                            aria-describedby="inputGroup-sizing-default"
                            onChange={(e) => this.props.onChange(e)}
                            checked = { this.checked() }
                        />

                    </div>
                    <div className={"row clear-both display-block justify-content-center"}>
                        {this.props.label}<br/>
                    </div>
                    <div className={"row clear-both display-block justify-content-center"}>
                        {this.displayHelperText()}
                        {this.displayError(this.props.error)}
                    </div>
                </div>

        )
    }
}


const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};


export default connect(mapStateToProps, mapDispatchToProps)(InputField);


