import { config } from 'config.js';

export const FAQ_UPDATE_FAQS = "FAQ_UPDATE_FAQS";

const debugFaqs = [
    {
        "id":1,
        "heading":"what is this all about?",
        "text":"There are many children in this world who are in need, but there are also many children who have a lot of 'stuff' and truthfully don't need any more of it. We have seen many children who get tremendous satisfaction from helping others and at the same time we know there are many adults who are stuck about what to get their son or daughter, their nephew or niece, their grandchild, their godchild, etc, for that special occasion. We believe that both adult and child will appreciate a new way of giving to the world."
    },
    {
        "id":3,
        "heading":"but why kidsgiving?",
        "text":"you could give directly to a charity for a child but where's the fun in that? by making your tax-deductible donation through kidsgiving, you're giving a child the power to choose how to have an impact, the satisfaction of an action they take to help others. and it all comes with a secret code to make it fun, a collectible token to make it memorable, and a card from you to make it special. you can't buy that in a store."
    },
    {
        "id":2,
        "heading":"is kidsgiving a good organization? where does the money go?",
        "text":"kidsgiving is a federally recognized charity with 501c3 designation; your tax-deductible gift amount goes to the charity selected by the child, and your full order amount (gift amount plus fee) is tax-deductible. kidsgiving also accepts tax-deductible donations directly to support our operations, including outreach, technical development, compensation to legal and financial professionals."
    },
    {
        "id":4,
        "heading":"how do i know that the partnership organizations are good charities ",
        "text":"Specially selected partner organizations are all highly rated by Charity Navigator based on their efficiency, moneys spent on programs and services, and transparency. "
    },
    {
        "id":5,
        "heading":"what is on a kidspage?",
        "text":"kidspage has information about the importance of charities, and allows a child to enter their special code to redeem their gift. The kidspage also has great ideas about creating wearable crafts from kidsgiving tokens, suggestions from kids about how to help others, as well as links to topics of interest about various charities. "
    },
    {
        "id":7,
        "heading":"the special child in my life would like to give but she actually needs stuff",
        "text":"Please contribute to the well-being of the child in your life directly, or direct the family to local service organizations. We understand the profound inequalities that allow some families to be in a position to donate more than others; kidsgiving focuses on these fortunate families as a small way to help address these inequalities"
    },
    {
        "id":8,
        "heading":"are children safe to use this website?",
        "text":"YES! the safety of children is our primary concern! we never ask for any personal information about a child, not even their real name. The gift giver supplies the only information we use to send a child a card, the same way you might send a gift of 'stuff' from Amazon. With only their special code, the child can redeem the gift and choose how to make a difference in the world."
    },
    {
        "id":9,
        "heading":"why is a fee added to my gift amount",
        "text":"Unlike many giving portals which keep a service fee from the original donation before sending the rest to a designated charity, kidsgiving adds a shipping and handling amount directly and transparently to your selected gift amount. The full selected gift amount will be sent to a charity selected by your gift recipient. The processing fee is calculated to include costs associated with printing, mailing, token production, paypal fee and legal/financial fees. kidsgiving financials are displayed on the Transparency page. Your full order amount (gift amount plus fee) is recognized as a tax-deductible donation to kidsgiving. "
    }];

export function getFaqs() {
    return function(dispatch) {
        fetch( config.apiUrl + config.urls.faq )
            .then((response) => {
                return response.json();
            })
            .then((responseJson) => {
                console.log("got faqs");
                /*sortFaqs*/
                responseJson.sort(function(a,b){
                    return (a.sortOrder - b.sortOrder);
                });

                return dispatch({type: FAQ_UPDATE_FAQS, faqs: responseJson});

            })
            .catch((error) => {
                console.log("error response");
                console.error(error);
                // /*sortFaqs*/
                // debugFaqs.sort(function(a,b){
                //    return (a.sortOrder - b.sortOrder);
                // });
                return dispatch({type: FAQ_UPDATE_FAQS, faqs: debugFaqs});

            });
    }
}

