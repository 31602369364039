/*set configurations*/
const payPalConfig = {
    key: 'AXuSmWejUstxIZL-5kXq8Z0WGGSYK-G4ex5Wkg3jK_r_L7u0VDoj2eO2YZ3ca01Cw0f7kkAB4RRQJGFV',
    env: 'production'
};

/*set up the config object*/
let config =  {
    env : "production",
    serverUrl: "https://dev.kidsgiving.us",
    debug: false,
    apiUrl:"https://api.kidsgiving.us",
    urls: {
        cardType:"/cardtype",
        donation:"/donation",
        payment : "/Order",
        redemptionCategories : "/RedemptionOrganizationType",
        redemptionOrganizations : "/RedemptionOrganizations",
        shippingMethod : "/package",
        faq : "/FAQ",
        funIdeas : "/FunIdeas",
        redeemNow : "/redemptionSession",

    },
    payPal: payPalConfig

};

/*update dev environments*/
if ( window.location.href.indexOf("localhost") >= 0 ){
    config.payPal = {
        key: 'AS6Sqv4La_YT2JldBB41SX6QpElqQykOSNjhqIcArkea80k_q2CokRzXgzBI-310If-0fU_cB15qHodD',
        env: 'sandbox'
    };
    config.debug = false;
    config.env = "local";
    config.apiUrl = "http://localhost:5002";


} else if ( window.location.href.indexOf("dev.kidsgiving") >= 0 ){
    config.payPal = {
        key: 'AS6Sqv4La_YT2JldBB41SX6QpElqQykOSNjhqIcArkea80k_q2CokRzXgzBI-310If-0fU_cB15qHodD',
        env: 'sandbox'

    };
    config.env = "dev";
    config.apiUrl = "https://api-dev.kidsgiving.us"
} else if ( window.location.href.indexOf("demo.kidsgiving") >= 0 ){
    config.payPal = {
        key: 'AS6Sqv4La_YT2JldBB41SX6QpElqQykOSNjhqIcArkea80k_q2CokRzXgzBI-310If-0fU_cB15qHodD',
        env: 'sandbox'

    };
    config.env = "demo";
    config.apiUrl = "https://api-demo.kidsgiving.us";
}

console.log("loading " + config.env);

export {config};

