import { config } from 'config.js';
import {SET_REDEEM_NOW_STEP} from "../../RedeemNow/actions/RedeemNowActions";

/*workflow*/
export const SET_GIFT_NOW_STEP = "SET_GIFT_NOW_STEP";
export const UPDATE_GIFT_CARD_ERRORS = "UPDATE_GIFT_CARD_ERRORS";
export const RESET_TO_INITIAL_STATE = "RESET_TO_INITIAL_STATE";
export const GIFT_CARD_INFO_ERROR = "GIFT_CARD_INFO_ERROR";


/*step 1*/
export const GIFT_CARD_INFO_GET_CARDTYPES = "GIFT_CARD_INFO_GET_CARDTYPES";
export const GIFT_CARD_INFO_CHANGE_PURCHASER_NAME = "GIFT_CARD_INFO_CHANGE_PURCHASER_NAME";
export const GIFT_CARD_INFO_CHANGE_RECIPIENT_NAME = "GIFT_CARD_INFO_CHANGE_RECIPIENT_NAME";
export const GIFT_CARD_INFO_CHANGE_GREETING_ID = "GIFT_CARD_INFO_CHANGE_GREETING_ID";
export const GIFT_CARD_INFO_SET_CUSTOM_GREETING_ENABLED = "GIFT_CARD_INFO_SET_CUSTOM_GREETING_ENABLED";
export const GIFT_CARD_INFO_SET_DONATION_ENABLED = "GIFT_CARD_INFO_SET_DONATION_ENABLED";
export const GIFT_CARD_INFO_SET_CUSTOM_GREETING_TEXT = "GIFT_CARD_INFO_SET_CUSTOM_GREETING_TEXT";
export const GIFT_CARD_INFO_SET_GREETING_FONT_SIZE = "GIFT_CARD_INFO_SET_GREETING_FONT_SIZE";
export const GIFT_CARD_INFO_CHANGE_EMAIL = "GIFT_CARD_INFO_CHANGE_EMAIL";
export const GIFT_CARD_INFO_CHANGE_AMOUNT = "GIFT_CARD_INFO_CHANGE_AMOUNT";
export const GIFT_CARD_INFO_TOGGLE_CUSTOM_AMOUNT = "GIFT_CARD_INFO_TOGGLE_CUSTOM_AMOUNT";
export const GIFT_CARD_INFO_AUTHORIZATION_CODE = "GIFT_CARD_INFO_AUTHORIZATION_CODE";
export const SHIP_TO_INFO_CHANGE_FIRST_NAME = "SHIP_TO_INFO_CHANGE_FIRST_NAME";
export const SHIP_TO_INFO_CHANGE_LAST_NAME = "SHIP_TO_INFO_CHANGE_LAST_NAME";
export const SHIP_TO_INFO_CHANGE_ADDRESS1 = "SHIP_TO_INFO_CHANGE_ADDRESS1";
export const SHIP_TO_INFO_CHANGE_ADDRESS2 = "SHIP_TO_INFO_CHANGE_ADDRESS2";
export const SHIP_TO_INFO_CHANGE_CITY = "SHIP_TO_INFO_CHANGE_CITY";
export const SHIP_TO_INFO_CHANGE_STATE = "SHIP_TO_INFO_CHANGE_STATE";
export const SHIP_TO_INFO_CHANGE_ZIPCODE = "SHIP_TO_INFO_CHANGE_ZIPCODE";
export const SHIP_TO_INFO_CHANGE_COUNTRY = "SHIP_TO_INFO_CHANGE_COUNTRY";

/*step 2 submit pre-order info*/
export const SHIPPING_INFO_CHANGE_SHIPPING_METHOD_ID = "SHIPPING_INFO_CHANGE_SHIPPING_METHOD_ID";
export const SHIPPING_INFO_CHANGE_SHIPPING_METHODS = "SHIPPING_INFO_CHANGE_SHIPPING_METHODS";
export const SUBMIT_GIFT_NOW = "SUBMIT_GIFT_NOW";
export const SUBMIT_GIFT_NOW_REQUEST_START = "SUBMIT_GIFT_NOW_REQUEST_START";
export const SUBMIT_GIFT_NOW_REQUEST_END = "SUBMIT_GIFT_NOW_REQUEST_END";
export const SET_PAYMENT_COMPLETE = "SET_PAYMENT_COMPLETE";
export const LOADING_COMPLETE = "LOADING_COMPLETE";

/*step 2.1 update order info*/
export const UPDATE_ORDER_ID = "UPDATE_ORDER_ID";
export const UPDATE_ORDER_IDENTIFIER = "UPDATE_ORDER_IDENTIFIER";
export const UPDATE_LINE_ITEM = "UPDATE_LINE_ITEM";

/*step 2.2 - submit paypal data after paypal execution*/
export const UPDATE_PAYPAL_DATA_START = "UPDATE_PAYPAL_DATA_START";
export const UPDATE_PAYPAL_DATA_END = "UPDATE_PAYPAL_DATA_END";

/*step 3 - thank you page*/
export const UPDATE_PREVIEW_STATE = "UPDATE_PREVIEW_STATE";


export function advanceToNextGiftNowStep(currentStep) {
    return {type: SET_GIFT_NOW_STEP, giftNowStep: (currentStep + 1) }
}

export function previousGiftNowStep(currentStep) {
    let nextStep = currentStep > 1 ? currentStep - 1 : 1;
    return {type: SET_GIFT_NOW_STEP, giftNowStep: (nextStep) }
}


export function getCardtypes() {
    console.log("getting cardTypes");
    return function(dispatch) {
        fetch( config.apiUrl + config.urls.cardType )
            .then((response) => {
                return response.json();
            })
            .then((responseJson) => {
                return dispatch({
                    type: GIFT_CARD_INFO_GET_CARDTYPES,
                    cardtypes: responseJson,
                    isCardTypesLoaded : true
                });

            })
            .catch((error) => {
                console.log("error response", error);
                return dispatch({
                    type: GIFT_CARD_INFO_ERROR,
                    hasError: true
                });
            });
    }
}

export function getShippingMethods() {
    return function(dispatch) {
        fetch( config.apiUrl + config.urls.shippingMethod )
            .then((response) => {
                return response.json();
            })
            .then((responseJson) => {

                /*format shipping methods*/
                for (let shippingMethodIndex in responseJson) {
                    responseJson[shippingMethodIndex].publicFacingName =
                        responseJson[shippingMethodIndex].publicFacingName + " ($" + responseJson[shippingMethodIndex].cost + ")";
                }

                return dispatch({
                    type: SHIPPING_INFO_CHANGE_SHIPPING_METHODS,
                    shippingMethods: responseJson,
                    isLoading: false
                });

            })
            .catch((error) => {
                console.log("error response", error);
                return dispatch({
                    type: GIFT_CARD_INFO_ERROR,
                    hasError: true
                });
            });
    }
}

export function submitGiftNow(giftCardInfo, greetings, shipToInfo, shippingInfo, shippingMethods, status, payPalData, successCallback, failureCallback) {
    return function(dispatch){
        let greeting = null;

        if ( giftCardInfo.customGreetingEnabled ){
            greeting = {
                name : "Custom Greeting",
                header : "Custom Greeting",
                line1 : giftCardInfo.customGreetingText
            }
        } else {
            greeting = greetings.find( function(greeting){
                return greeting.id === this;
            }, giftCardInfo.greetingId);
        }

        let shippingMethod = shippingMethods.find( function(shippingMethod){
            return shippingMethod.id === this;
        },shippingInfo.shippingMethodId);

        let lineItems = [];
        if ( giftCardInfo.lineItems && giftCardInfo.lineItems.length === 0 ){
            /*first time through, line items not set*/
            lineItems = formatLineItems(setInitialLineItems(giftCardInfo, shippingMethod));
        } else {
            lineItems = formatLineItems(giftCardInfo.lineItems);
        }

        let requestBody = {

            purchaseAccount : {
                emailAddress : giftCardInfo.email
            },
            status: status,
            fromName : giftCardInfo.purchaserName,
            recipientName : giftCardInfo.recipientName,
            shippingFirstName :  shipToInfo.firstName,
            shippingLastName : shipToInfo.lastName,
            shippingAddress1 : shipToInfo.address1,
            shippingAddress2 : shipToInfo.address2,
            shippingCity : shipToInfo.city,
            shippingState : shipToInfo.state,
            shippingCountry : shipToInfo.country,
            shippingZip : shipToInfo.zipcode,
            package : {
                id: shippingMethod.id,
                name : shippingMethod.name,
                publicFacingName : shippingMethod.publicFacingName,
                description : shippingMethod.description,
                tracked : shippingMethod.tracked,
                cost : shippingMethod.cost
            },
            cardType : {
                id: greeting.id,
                name : greeting.name,
                publicFacingName : greeting.publicFacingName,
                header : greeting.header,
                line1 : greeting.line1,
                line2 : greeting.line2
            },
            headerFontSize : giftCardInfo.greetingFontSize,
            headerLineHeight : giftCardInfo.greetingLineHeight,
            cardTemplateId : 1,
            payPalData : payPalData,
            lineItems : lineItems
        };
        if ( giftCardInfo.customGreetingEnabled ){
            requestBody.customMessage = giftCardInfo.customGreetingText;
        }

        dispatch({type:"SUBMIT_GIFT_NOW_REQUEST_START"});

        /*start request*/
        fetch(config.apiUrl + config.urls.payment, {
            method: "POST",
            body: JSON.stringify(requestBody),
            headers: {
                'Content-Type': 'application/json'
            }
        })

        .then((response) => {
            if (response.status !== 200){
                console.log("response", response);
                throw new Error("did not return a successful response");
            }
            return response.json();
        })
        .then((responseJson) => {

            /*update the order id*/
            if (responseJson.id) {
                dispatch({type: "UPDATE_ORDER_ID", value: responseJson.id});
            }

            /*update the order identifier*/
            if (responseJson.orderIdentifier) {
                dispatch({type: "UPDATE_ORDER_IDENTIFIER", value: responseJson.orderIdentifier});
            }

            /*update the line items*/
            if (responseJson.lineItems) {
                for (let newLineItemIndex in responseJson.lineItems) {
                    for (let lineItemIndex in giftCardInfo.lineItems) {

                        if (giftCardInfo.lineItems[lineItemIndex].lineItem.toLowerCase() === responseJson.lineItems[newLineItemIndex].lineItem.toLowerCase()) {

                            /*dispatch to change state*/
                            dispatch({type: "UPDATE_LINE_ITEM", lineItem: responseJson.lineItems[newLineItemIndex]});

                            /*I suspect update props as well, since the sucecssCallback is called before the state updates.*/
                            giftCardInfo.lineItems[lineItemIndex].lineItem = responseJson.lineItems[newLineItemIndex].lineItem;

                        }
                    }
                }
            }
            return responseJson;
        }).then(() => {
            console.log("successCallback");
            successCallback();

        }).then(() => {
            console.log("dispatching SUBMIT_GIFT_NOW_REQUEST_END");
            dispatch({type:"SUBMIT_GIFT_NOW_REQUEST_END"});
        }).catch((error) => {
            console.log("error response");
            console.error(error);
            failureCallback();
            dispatch({type:"SUBMIT_GIFT_NOW_REQUEST_END"});
        });

    };

}

export function updatePaypalDataByOrder(orderId, payPalData, successCallback) {

    return function(dispatch){

        let requestBody = {
            "payPalId":payPalData.id,
            "intent":payPalData.intent,
            "state":payPalData.state,
            "cart":payPalData.cart,
            "createTime":payPalData.createTime,
            "transactions":payPalData.transactions
        };

        if ( payPalData.payer && payPalData.payer.payer_info) {
            requestBody.payerEmail = payPalData.payer.payer_info.email;
            requestBody.payeerFirstName = payPalData.payer.payer_info.first_name;
            requestBody.payerLastName = payPalData.payer.payer_info.last_name;
            requestBody.payerId = payPalData.payer.payer_info.payer_id;
        }

        dispatch({type:"UPDATE_PAYPAL_DATA_START"});

        /*start request*/

        let orderUrl = config.apiUrl + config.urls.payment + "/" + orderId + "/paypalData";
        fetch( orderUrl, {
            method: "POST",
            body: JSON.stringify(requestBody),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(() => {
            successCallback();
        })
            .catch((error) => {
                console.log("error response");
                console.error(error);
            });

        dispatch({type:"UPDATE_PAYPAL_DATA_END"});

    };

}

let setInitialLineItems = function(giftCardInfo, shippingMethod){

    let lineItems = [];
    /*add card amount*/
    lineItems.push({
        lineItem : "giftAmount",
        amount: giftCardInfo.amount
    });

    /*add shipping cost*/
    lineItems.push({
        lineItem : "shipping",
        amount: shippingMethod.cost
    });
    /*add donation amount*/
    if ( giftCardInfo.donationEnabled ){
        lineItems.push({
            lineItem : "donation",
            amount: giftCardInfo.donationAmount
        });
    }
    giftCardInfo.lineItems = lineItems;
    return lineItems;

};

let formatLineItems = function(lineItems){

    let newLineItems = [];
    for ( let lineItemIndex in lineItems ){

        let formattedAmount = Number(parseFloat(lineItems[lineItemIndex].amount));

        newLineItems.push({
            orderId : lineItems[lineItemIndex].orderId,
            lineItem : lineItems[lineItemIndex].lineItem,
            amount : formattedAmount,
            note: lineItems[lineItemIndex].note
        });
    }

    return newLineItems;
};

export function updatePreviewState(isPreview, onSuccess) {
    return ( dispatch ) => {
        dispatch({ type: UPDATE_PREVIEW_STATE, isPreview: isPreview });
        if (onSuccess) {
            onSuccess();
        }
    }
}

export function advanceToNextRedeemNowStep(currentStep) {
    return {type: SET_REDEEM_NOW_STEP, redeemNowStep: (currentStep + 1) }
}
