import React, {Component} from 'react';
import {connect} from "react-redux";

import * as Actions from "../actions/RedeemNowActions";

import ReCAPTCHA from "react-google-recaptcha";

// import kidsPageHeader from 'images/kidspage.jpg';
import tokenImage from 'images/token-logo.png';
import giftBoxImage from 'images/giftbox.png';
import giftBoxClosedImage from 'images/giftbox-closed-lid.png';
import 'RedeemNow/css/RedeemNow.scss'
// import confetti from 'images/confetti.png';

import { config } from 'config.js';
import {push} from "react-router-redux";

class RedeemNowCodeEntry extends Component {

    constructor(props){
        super(props);
        this.submitRedeemNowCode = this.submitRedeemNowCode.bind(this);
        this.state = {
            codeEntered: false,
            isInvalidCode: false,
            invalidCodeErrorMessage: null
        };
    }


    componentDidMount() {
        window.scrollTo(0,0);
        if ( this.props.currentRedeemNowStep === 1){
            this.props.updatePreviewState(false);
        }

    };

    submitRedeemNowCode = () => {

        if ( this.state.codeEntered ){
            this.props.submitRedeemNowCode(
                this.props.redeemNowCode,
                this.props.captcha,
                () => {
                    this.props.advanceToNextRedeemNowStep(this.props.currentRedeemNowStep)},
                (invalidCodeErrorMessage) => {
                    console.log("redeemNowCodeEntry.js :: invalid code - ");
                    this.setState(() => ({
                        isInvalidCode  : true,
                        invalidCodeErrorMessage  : invalidCodeErrorMessage
                    }))
                }
            );
        }

    };

    startPreview = () => {
        this.props.updatePreviewState(
            true,
            () => {
                this.props.advanceToNextRedeemNowStep(this.props.currentRedeemNowStep)}
        );
    };

    onChange(value) {
        this.props.updateRedeemNowCapchta(value);
    }

    showRecaptchaOrSubmit() {

        if ( !this.props.captcha && !config.debug ) {
            return (
                <div className={"row m-auto"}>
                    <div className={"col-12 m-auto"}>
                        <h3 className={"redeem-now-text"}>are you a human that&nbsp;
                            <span>received a token?</span>
                        </h3>
                    </div>
                    <div className={"mt-3"}>
                        <ReCAPTCHA
                            sitekey="6LddgmwUAAAAAPT5p7bceBFcfIAxA55kj-nAoIFK"
                            onChange={ (value) => this.onChange(value) }
                        />
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <h3 className={"redeem-now-text"}>great! enter your special code, then click the present to continue!</h3>
                        </div>
                    </div>
                    <div className={"row m-2"}>
                        <div className={"offset-md-2 col-6"}>
                            <div>
                                <input type="text" className={"form-control " + (this.state.isInvalidCode ? "is-invalid" : "")}
                                       value={this.props.redeemNowCode} aria-label="Default" aria-describedby="inputGroup-sizing-default"
                                       onChange={ (e) => {
                                                this.updateRedeemNowCode(e.target.value);
                                        }}
                                />
                                {this.showInvalidCodeMessage(this.state.isInvalidCode, this.state.invalidCodeErrorMessage)}
                            </div>
                        </div>
                        <div className={"col-4"}>
                            <div className={"redeem-now-submit-button " + (this.state.codeEntered ? "code-entered" : "code-not-entered") }>
                                <div className={"background-glow"}>&nbsp;</div>
                                <img  onClick={ () => this.submitRedeemNowCode() }
                                      src={this.state.codeEntered ? giftBoxImage : giftBoxClosedImage}
                                      alt={"present box"}
                                      className={"present-box-image"}
                                />
                                <div className={"click-here shimmer"} onClick={ () => this.submitRedeemNowCode() }>
                                    {this.state.codeEntered ? "click here!" : ""}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            );
        }

    }

    updateRedeemNowCode = (value) => {
        this.props.updateRedeemNowCode(value);

        if( value && value.length > 2 ){
            this.setState((state, props) => ({
                codeEntered  : true
            }));
        } else {
            this.setState((state, props) => ({
                codeEntered  : false
            }));
        }

    };

    showInvalidCodeMessage = (isInvalidCode, invalidCodeMessage) =>{
        if( isInvalidCode ){
            return (
                <div className="invalid-feedback">
                    {invalidCodeMessage}
                </div>
            );
        }
    };

    render() {

        return (

            <div className={"redeem-now-code-entry justify-content-center"}>
                <img src={"/images/art-blur-close-up-6334.jpg" }
                     alt={"canvas with heart painted on it"}
                     style={{
                    objectFit: 'cover',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%'}} />

                <div className={"row title-row"}>
                    <div className={"col-12 "}>
                        <span>do you have a kidsgiving gift card?</span>
                    </div>
                </div>

                <div className={"row equal mt-3 mx-0"}>

                    <div className={"col-xl-4 col-md-6 offset-xl-2"}>
                        <div className={"p-4 p-md-2 h-100"}>
                            <div className={"card card-body yellow-box-shadow redeem-now-card-entry opacity"}>
                                <div className={"recaptcha-or-submit row m-auto justify-content-center"}>
                                    <div className={"col-12"}>
                                        {this.showRecaptchaOrSubmit()}
                                    </div>
                                </div>

                                <div className={"row mt-auto"}>
                                    <div className={"col-12 w-100"}>
                                        <hr className={"w-100"}/>
                                        <div>
                                            <p className={"m-1"}>
                                                don't have a token yet?  or just want to take a peak?
                                            </p>
                                            <button type="button" className={" btn btn-secondary mt-2"}
                                                    onClick={() => {
                                                        console.log("clicked fake redeem now");
                                                        this.startPreview();
                                                    }}
                                            >
                                                    click here to preview
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className={"col-xl-4 col-md-6"}>
                        <div className={"p-4 p-md-2 h-100"}>
                            <div className={"card card-body yellow-box-shadow redeem-now-card-entry opacity clickable"}
                                 onClick={() => this.props.changePageToFunIdeas() }
                            >
                                <div className={"fun-ideas-container container-fluid d-flex flex-column"}>
                                    <div className={"row flex-grow-1 align-items-end"}>
                                        <div className={"col-12"}>
                                            <h3 className={"redeem-now-header m-0"}>click to see some fun ways</h3>
                                        </div>
                                    </div>
                                    <div className={"row flex-grow-1"}>
                                        <div className={"col m-auto"}>
                                            <div className={"token-image-container"}>
                                                <img className={"mx-auto d-block"} src={tokenImage} alt={"tokenImage"} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"row flex-grow-1 align-items-start"}>
                                        <div className={"col-12"}>
                                            <h3 className={"redeem-now-header m-0"}>to use your kidsgiving token</h3>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

                <div className="row justify-content-center m-0 mt-5">
                    <div className={"col-lg-7 mb-4 "}>
                        <div className={"gray-card-background text-justify m-3"}>
                            <p><span className={"emphasis-text"}> Have you ever felt the need to help someone? We’re sure the answer is yes</span>, because giving to others is in our nature. Maybe you saw someone falling or needing help because of an illness. Whether it’s someone we know or a complete stranger, our first impulse is to help.</p>

                            <p>Charities are organizations that provide help on a large scale by raising money for the needy or the suffering. Sadly, there are plenty of people and animals that need our help. Over a billion people in the world live in extreme poverty and can’t access basic services such as health and education. Animals and the earth need our help too!  There are government agencies that help, but it’s not enough. When ordinary people donate money to charities, we contribute to building the world we want to live in. You don’t have to have a lot of money to make a difference; even small amounts you give to a charity can have a huge impact to improve the quality of life for people or animals that are suffering.</p>

                            <p>By selecting one of the responsible charities from the categories in kidsgiving, you get to make a decision similar to voting in an election. You decide how you want to help the world; <span className={"emphasis-text"}>you help to create the world we want to live in.</span></p>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        redeemNow : state.redeemNow,
        currentRedeemNowStep : state.redeemNow.redeemNowStep,
        redeemNowCode : state.redeemNow.redeemNowCode,
        captcha : state.redeemNow.captcha,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        advanceToNextRedeemNowStep : (currentRedeemNowStep) => dispatch(Actions.advanceToNextRedeemNowStep(currentRedeemNowStep)),
        submitRedeemNowCode: (redeemNowCode, captcha, onSuccess, onFailure) => dispatch(Actions.submitRedeemNowCode(redeemNowCode, captcha, onSuccess, onFailure)),
        updateRedeemNowCode: (value) => dispatch ({type: Actions.UPDATE_REDEEM_NOW_CODE, value : value}),
        updateRedeemNowCapchta: (value) => dispatch ({type: Actions.UPDATE_REDEEM_NOW_CAPTCHA, value : value}),
        updatePreviewState: (isPreview, onSuccess) => dispatch(Actions.updatePreviewState(isPreview, onSuccess)),
        changePageToFunIdeas : () => dispatch(push("/fun-ideas")),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(RedeemNowCodeEntry);

