import React, { Component } from 'react';
import {connect} from "react-redux";
import { push } from 'react-router-redux';

import 'site/css/Footer.css'

import {SET_GIFT_NOW_STEP} from "../../GiftNow/actions/GiftNowActions";
import {SET_REDEEM_NOW_STEP} from "../../RedeemNow/actions/RedeemNowActions";

class Footer extends Component {

    render() {

        let location  = this.props.location;

        return(
            <div className="row w-p100 m-0 mt-2 footer-row ">
                <div className={"col-sm-6 col-lg-9 align-self-center"}>
                    <nav className="navbar navbar-expand-lg ">
                        <button className="navbar-toggler navbar-light white-background " type="button" data-toggle="collapse" data-target="#navbarFooter" aria-controls="navbarFooter" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon "></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarFooter">
                            <ul className="nav navbar-main justify-content-start white">
                                <li className={"nav-item " + (location === "/" ? "active":"")}>
                                    <span onClick={() => this.props.changePageToHome() } >home</span>
                                </li>
                                <li className={"nav-item " + (location === "/gift-now" ? "active":"")}>
                                    <span onClick={() => this.props.changePageToGiftNow() } >gift now</span>
                                </li>
                                <li className={"nav-item " + (location === "/about-us" ? "active":"")}>
                                    <span onClick={() => this.props.changePageToAboutUs() } >about us</span>
                                </li>
                                <li className={"nav-item " + (location === "/kidspage" ? "active":"")}>
                                    <span onClick={() => this.props.changePageToKidsPage() } >kidspage</span>
                                </li>
                                <li className={"nav-item " + (location === "/faq" ? "active":"")}>
                                    <span onClick={() => this.props.changePageToFAQ() } >faq</span>
                                </li>
                                <li className={"nav-item " + (location === "/donate" ? "active":"")}>
                                    <span onClick={() => this.props.changePageToDonate() } >support kidsgiving</span>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
                <div className={"col-sm-6 col-lg-3 align-self-center text-right"}>
                    <span>kidsgiving - copyright {new Date().getFullYear()}</span>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        changePageToHome : () => {dispatch(push("/"));},
        changePageToGiftNow : () => {
            dispatch({type: SET_GIFT_NOW_STEP, giftNowStep: (1) });
            dispatch(push("/gift-now" ));
        },
        changePageToRedeemNow : () => dispatch(push("/redeem-now")),
        changePageToAboutUs : () => dispatch(push("/about-us")),
        changePageToKidsPage : () => {
            dispatch({type: SET_REDEEM_NOW_STEP, redeemNowStep: (1) });
            dispatch(push("/kidspage"))
        },
        changePageToFunIdeas : () => dispatch(push("/fun-ideas")),
        changePageToFAQ : () => dispatch(push("/faq")),
        changePageToDonate: () => dispatch(push("/donate"))

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Footer);


