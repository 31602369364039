import React, { Component } from "react";
import {connect} from "react-redux";


class Modal extends Component {

    componentDidMount(){
        /*document.getElementsByClassName('modal').modal('show');
        document.getElementsByClassName('modal').on('hidden.bs.modal', this.props.handleHideModal);*/
    }

    render(){
        console.log("returning modal");
        return (
            <div className="modal show" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Modal title</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Modal body text goes here.</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Modal);

/*
Modal.propTypes = {
    handleHideModal: React.PropTypes.func.isRequired
};*/
