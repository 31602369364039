import React, {Component} from 'react';
import {connect} from "react-redux";

import 'RedeemNow/css/RedeemNow.scss'

class MiniCharityCard extends Component {

    render() {
        return (
            <div key={this.props.key} className={ "charity-card-container col-12 col-md-6 col-lg-4 col-xl-3 " } >


                {/*back of the card*/}
                <div className={"charity-card card light-opacity w-100 "}  >
                    <div className={"card-body text-left"} >

                        <div className="row">
                            <div className="col-12">
                                <h6 className={"text-left charity-card-header"}> {this.props.name} </h6>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 description-long">
                                {this.props.description}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}



const mapStateToProps = (state) => {
    return {
        redeemNow : state.redeemNow,
        currentRedeemNowStep : state.redeemNow.redeemNowStep,
        redemption : state.redeemNow.redemption,
        redemptionAmount : state.redeemNow.redemption.amount,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MiniCharityCard);

