import React from 'react';

const initialState = {
    bios: {
        lauren: () => {
            return (<div>
                <p>
                    Lauren's nephew Sam loves doing for others, and she wanted to give him something that meant
                    something special to him on his birthday, and something that meant something special to her. and
                    that started it all. over several years of refining the concept, an incredible development team took
                    Lauren's concept of workflows and web pages and made her dream come true. we hope you enjoy a new
                    way of giving as much as Lauren, Sam, and the rest of us here at kidsgiving do.
                </p>
                <p>
                    Lauren loves foreign languages and travel, and tries to keep up with her tennis and soccer
                    teammates.
                </p>
            </div>)
        },
        steelsen: () => {
            return (<div>
                <p>
                    Steelsen is a master of all things technical. he took lauren's original concept of workflow and web
                    pages and made her dream come true.
                </p>
                <p>
                    Steelsen is also the owner of getitguru.com, a consulting company specializing in academic library
                    fulfillment systems.
                </p>
            </div>)
        },
        nate: () => {
            return (<div>
                <p>
                    Nate shares the kidsgiving development with Steelsen and is excited to see the site coming to life.
                </p>
                <p>
                    he spends his free time rock climbing, playing soccer, playing board games, and hoping to hear new
                    updates from his builder as work continues on his new-construction home.
                </p>
            </div>)
        },
        jake: () => {
            return (<div>
                <p>
                    Jake is pleased to serve on the board of kidsgiving. in his 'day job,' Jake works with fortunate
                    families and enjoys helping them achieve their financial goals. a rewarding part of this is when he
                    can assist them with charitable giving strategies, and especially when they involve multiple
                    generations of their family.
                </p>
                <p>in his spare time, Jake enjoys curling and petting dogs.</p>
            </div>)
        },
        specialThanks: () => {
            return (<div className='about-us-special-thanks'>
                with special thanks to:
                <div className='my-2'>Wanda Hawkins</div>
                <div className='my-2'>Corey Hudson</div>
                <div>ProBono Partnership</div>
            </div>)
        }
    }
};


export default function AboutUs(state = initialState, action) {

    switch (action.type) {

        default:
            return state;
    }
}