import React, {Component} from 'react';
import {connect} from "react-redux";

import * as Actions from "../actions/FunIdeasActions";
import {FunIdeasCard} from './';

import 'FunIdeas/scss/FunIdeas.scss';

class FunIdeas extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        try {
            this.props.getFunIdeas();
        } catch (e) {
            console.log("Error loading faqs:");
            console.log(e);
        }

    }

    render() {
        return (
            <div className={"faq-background-container "}>

                <div className={"container-fluid d-flex"}>
                    <div className={"row m-0 mb-5"}>
                        {this.props.funIdeas.map(function (funIdea, i) {
                            return (
                                <div className={"col-md-6 mt-5 px-4"}>
                                    <FunIdeasCard
                                        key={"funIdea-" + i}
                                        heading={funIdea.heading}
                                        text={funIdea.text}
                                        iconUrl={funIdea.iconUrl}
                                    />
                                </div>
                            );
                        })}

                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        funIdeas: state.funIdeas.funIdeas
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getFunIdeas: () => {
            dispatch(Actions.getFunIdeas())
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FunIdeas);

