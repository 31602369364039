import React, {Component} from 'react';
import {connect} from "react-redux";
import * as Actions from "../actions/GiftNowActions";
import { config } from 'config.js';
import { RedemptionPreview } from './';

import {push} from "react-router-redux";
import {SET_REDEEM_NOW_STEP, UPDATE_PREVIEW_STATE} from "../../RedeemNow/actions/RedeemNowActions";
import {createBrowserHistory} from "history";

class GiftCardThankYou extends Component {

    unListenGiftCardShippingForm = () => {console.log("error:: unListenGiftCardShippingForm has not been set")};

    componentDidMount() {
        window.scrollTo(0,0);

        const history = createBrowserHistory();

        const giftCardShippingFormListener = history.listen((location, action) => {
            if ( action === "POP"){
                console.log("resetting gift card pages");
                this.props.resetToInitialState();
            }
            this.unListenGiftCardShippingForm();
        });

        this.unListenGiftCardShippingForm = history.listen(giftCardShippingFormListener);

        window.onpopstate  = () => {
            history.go(1);
            window.onpopstate = () => {};
        }

    }

    componentWillUnmount() {
        this.unListenGiftCardShippingForm();
    }

    debugInfo(){
        if (config.debug ){
            return (<div style={{textAlign:"left", margin:"10px 40px"}}>
                currentGiftNowStep: {JSON.stringify(this.props.currentGiftNowStep)}<br/>
                giftCardInfo: {JSON.stringify(this.props.giftCardInfo)}<br/>
                customGreeting: {JSON.stringify(this.props.customGreeting)}<br/>
                greetings: {JSON.stringify(this.props.greetings)}<br/>
            </div>);
        }
    }

    startPreview = () => {
        console.log("startPreview:");
        this.props.advanceToNextRedeemNowStep(this.props.giftCardInfo.recipientName,
            this.props.giftCardInfo.purchaserName, this.props.giftCardInfo.amount);
    };

    render() {
        return (
            <div className={"thank-you-container"}>
                <img src={"/images/art-blur-close-up-6334.jpg" }
                     alt={"canvas with heart painted on it"}
                     style={{
                    objectFit: 'cover',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%'}} />

                <div className={"row m-0"}>
                    <div className={"col-12 col-md-8 offset-md-2 mt-4"}>
                        <div className={"card card-body"}>
                            <div className={"row m-3"}>
                                <div className={"col-12"}>
                                    <h4>THANK YOU!</h4>
                                </div>
                            </div>

                            <div className={"row m-3"}>
                                <div className={"col-12"}>
                                    <p>
                                        we have specially selected organizations which are either regional and dear to our hearts, and/or are larger in scope and highly rated by Charity Navigator based on their efficiency, money spent on programs and services, and transparency.
                                    </p>
                                    <p>
                                        below is a quick preview of what the child will see when they go to redeem their gift
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <RedemptionPreview
                    customGreeting = {this.props.giftCardInfo.customGreetingEnabled}
                    startPreview = {this.startPreview}
                />

            </div>
        )
    }
}



const mapStateToProps = (state) => {
    return {
        currentGiftNowStep : state.giftNow.giftNowStep,
        giftCardInfo : state.giftNow.giftCardInfo,
        customGreeting: state.giftNow.customGreeting,
        greetings: state.giftNow.greetings,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetToInitialState: () => {dispatch({type:Actions.RESET_TO_INITIAL_STATE})},
        advanceToNextGiftNowStep : (currentGiftNowStep) => dispatch(Actions.advanceToNextGiftNowStep(currentGiftNowStep)),
        previousGiftNowStep : (currentGiftNowStep) => dispatch(Actions.previousGiftNowStep(currentGiftNowStep)),
        advanceToNextRedeemNowStep : (recipientName, purchaserName, amount) => {
            dispatch( push("/kidspage"));
            dispatch({type: SET_REDEEM_NOW_STEP, redeemNowStep: (2) });
            dispatch({
                type: UPDATE_PREVIEW_STATE,
                isPreview: true,
                recipientName: recipientName,
                purchaserName: purchaserName,
                amount: amount });

        },
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(GiftCardThankYou);

