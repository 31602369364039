import React from 'react';
import {connect} from "react-redux";
import ReactDOM from 'react-dom';
import paypal from 'paypal-checkout';

import { config } from 'config.js';

const PayPalButton = paypal.Button.driver('react', {React, ReactDOM});

class PaypalButton extends React.Component {

    render() {

        const key = config.payPal.key;
        const env = config.payPal.env;

        const client = {
            sandbox: key,
            production: key
        };
        const paymentAmount = this.props.amount;

        let kidsgivingPrepaymentRequest = this.props.kidsgivingPrepaymentRequest;
        let afterAuthorizeCallback = this.props.afterAuthorizeCallback;
        let afterErrorCallback = this.props.afterErrorCallback;

        let onAuthorize = function (data, actions) {
            return kidsgivingPrepaymentRequest( ()=>actions.payment.execute().then(function (data) {
                // Show a success page to the buyer
                afterAuthorizeCallback(data);
            }));

        };

        let onError = function (error) {
            console.log("onError()");
            console.warn(error);
            afterErrorCallback(error);
        };

        let payment = function(){

            const env = config.payPal.env;

            return paypal.rest.payment.create(env, client, {
                transactions: [
                    {
                        amount: {total: paymentAmount, currency: 'USD'}
                    }
                ]
            });
        };

        let style = {
            size: 'responsive',
            tagline: 'false'
        };

        return (
            <PayPalButton env={env}
                          client={client}
                          payment={payment}
                          commit={true} // Optional: show a 'Pay Now' button in the checkout flow
                          onAuthorize={onAuthorize}
                          onError={onError}
                          style= {style}
            />
        );
    }
}


const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};


export default connect(mapStateToProps, mapDispatchToProps)(PaypalButton );

